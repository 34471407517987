import React from "react";
import {Typography} from "@mui/material";

export default function FirstTitle({children}) {
  return (
    <Typography variant="h2" color='primary.main' sx={{m: '50px 0px'}}>
      {children}
    </Typography>
  )
}
