import React from "react";
import "../../styles/coinRain.scss";


export default function CoinRain() {
  return (
    <div className="pcoin-rain">
      <img src="//cdn04.pinkoi.com/pinkoi.site/my_membership/ic_pcoins.svg" height='50px'/>
      <img src="//cdn04.pinkoi.com/pinkoi.site/my_membership/ic_pcoins.svg" height='56px'/>
      <img src="//cdn04.pinkoi.com/pinkoi.site/my_membership/ic_pcoins.svg" height='47px'/>
      <img src="//cdn04.pinkoi.com/pinkoi.site/my_membership/ic_pcoins.svg" height='63px'/>
      <img src="//cdn04.pinkoi.com/pinkoi.site/my_membership/ic_pcoins.svg" height='40px'/>
      <img src="//cdn04.pinkoi.com/pinkoi.site/my_membership/ic_pcoins.svg" height='51px'/>
      <img src="//cdn04.pinkoi.com/pinkoi.site/my_membership/ic_pcoins.svg" height='55px'/>
      <img src="//cdn04.pinkoi.com/pinkoi.site/my_membership/ic_pcoins.svg" height='43px'/>
      <img src="//cdn04.pinkoi.com/pinkoi.site/my_membership/ic_pcoins.svg" height='51px'/>
      <img src="//cdn04.pinkoi.com/pinkoi.site/my_membership/ic_pcoins.svg" height='48px'/>
      <img src="//cdn04.pinkoi.com/pinkoi.site/my_membership/ic_pcoins.svg" height='48px'/>
      <img src="//cdn04.pinkoi.com/pinkoi.site/my_membership/ic_pcoins.svg" height='48px'/>
    </div>
  )
}
