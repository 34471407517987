import React, {useState, useEffect} from "react";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {NavLink} from "react-router-dom";
import Logo from "../../assets/imgs/Logo.png";
import { useSelector } from 'react-redux';
import FirstTitle from "../components/custom/FirstTitle";
import {selectGlobalParams} from "../../slices/strategiesSlice";
import {useAccount, useContractReads} from "wagmi";
import {poolConfig} from "../../config/pools";
import {convertBigIntToNumberWithDecimal, fixedNumber} from "../../utils/common";
import {pointList, tokenConfig} from "../../config/tokens";
import SecondTitle from "../components/custom/SecondTitle";
import {owntheme} from "../../themes";
import * as CurrencyFormat from "react-currency-format";
import CardRowTextValue from "../components/custom/CardRowTextValue";


export default function Dashboard() {
  const globalParams = useSelector(selectGlobalParams);
  // const referral = useSelector(selectReferral);
  // const referralParam = referral ? "?referral=" + referral : "";

  const { address } = useAccount();

  let newState = {};
  poolConfig.forEach((item) => {
    newState[item.id] = 0;
  });

  const [totalSupplys, setTotalSupplys] = useState();
  const [balances, setBalances] = useState();
  // const [totalDepositAmounts, setTotalDepositAmounts] = useState(newState);
  const [totalDepositETHValues, setTotalDepositETHValues] = useState(newState);
  const [totalDepositValues, setTotalDepositValues] = useState(newState);

  const [depositAmounts, setDepositAmounts] = useState({});
  const [depositETHValues, setDepositETHValues] = useState(newState);
  const [depositValues, setDepositValues] = useState(newState);

  const [userRewards, setUserRewards] = useState(()=> {
    let newUserRewards = {};
    poolConfig.forEach((item,index) => {
      newUserRewards[item.id] = {};
      newUserRewards[item.id][item.rewardPoints[0]] = 0;
      newUserRewards[item.id][item.rewardPoints[1]] = 0;
    });
    return newUserRewards;
  });

  useContractReads({
    contracts: pointList.map((item) => ({
      ...tokenConfig[item].contract,
      functionName: 'totalSupply',
    })),
    watch: true,
allowFailure: true,
    onSuccess(data) {
      let newResult = {};
      pointList.forEach((item,index) => {
        if (data[index].status === 'success') {
          newResult[item] = convertBigIntToNumberWithDecimal(data[index].result, 18);
        } else {
          newResult[item] = 0;
        }
      });

      setTotalSupplys(newResult);
    },
  });

  useContractReads({
    contracts: pointList.map((item) => ({
      ...tokenConfig[item].contract,
      functionName: 'balanceOf',
      args: [address],
    })),
    watch: true,
allowFailure: true,
    onSuccess(data) {
      let newResult = {};
      pointList.forEach((item,index) => {
        if (data[index].status === 'success') {
          newResult[item] = convertBigIntToNumberWithDecimal(data[index].result, 18);
        } else {
          newResult[item] = 0;
        }
      });

      setBalances(newResult);
    },
  });

  useContractReads({
    contracts: poolConfig.map((item) => ({
      ...item.contracts.strategy,
      functionName: 'getTotalStakedValue',
    })),
    watch: true,
allowFailure: true,
    onSuccess(data) {
      let newTotalDepositETHValues = {};
      poolConfig.forEach((item,index) => {
        if (data[index].status === 'success') {
          newTotalDepositETHValues[item.id] = convertBigIntToNumberWithDecimal(data[index].result, 18);
        } else {
          newTotalDepositETHValues[item.id] = 0;
        }
      });
      setTotalDepositETHValues(newTotalDepositETHValues);
    },
  });

  useContractReads({
    contracts: poolConfig.map((item) => ({
      ...item.contracts.strategy,
      functionName: 'stakedShares',
      args: [address],
    })),
    watch: true,
allowFailure: true,
    onSuccess(data) {
      let newDepositAmounts = {};
      poolConfig.forEach((item,index) => {
        if (data[index].status === 'success') {
          newDepositAmounts[item.id] = convertBigIntToNumberWithDecimal(data[index].result, 18);
        } else {
          newDepositAmounts[item.id] = 0;
        }
      });

      setDepositAmounts(newDepositAmounts);
    },
  });

  useContractReads({
    contracts: poolConfig.map((item) => ({
      ...item.contracts.strategy,
      functionName: 'getUserStakedValue',
      args: [address],
    })),
    watch: true,
allowFailure: true,
    onSuccess(data) {
      let newDepositETHValues = {};
      poolConfig.forEach((item,index) => {
        if (data[index].status === 'success') {
          newDepositETHValues[item.id] = convertBigIntToNumberWithDecimal(data[index].result, 18);
        } else {
          newDepositETHValues[item.id] = 0;
        }
      });

      setDepositETHValues(newDepositETHValues);
    },
  });

  useContractReads({
    contracts: poolConfig.map((item) => ({
      ...item.contracts.distributor,
      functionName: 'getAvailableRewards',
      args: [address],
    })),
    watch: true,
allowFailure: true,
    onSuccess(data) {
      let newUserRewards = {};
      poolConfig.forEach((item,index) => {
        if (data[index].status === 'success') {
          newUserRewards[item.id] = {};
          newUserRewards[item.id][item.rewardPoints[0]] = convertBigIntToNumberWithDecimal(data[index].result[0], 18);
          newUserRewards[item.id][item.rewardPoints[1]] = convertBigIntToNumberWithDecimal(data[index].result[1], 18);

          if (data[index].result.length > 2) {
            newUserRewards[item.id][item.rewardPoints[0]] += convertBigIntToNumberWithDecimal(data[index].result[2], 18);
          }
        }
      });

      setUserRewards(newUserRewards);
    },
  });

  useEffect(() => {
    let newTotalDepositValues = {};
    let newDepositValues = {};
    poolConfig.forEach((item,index) => {
      newTotalDepositValues[item.id] = fixedNumber(totalDepositETHValues[item.id] * globalParams.ethPrice ? totalDepositETHValues[item.id] * globalParams.ethPrice : 0, 2);
      newDepositValues[item.id] = fixedNumber(depositETHValues[item.id] * globalParams.ethPrice ? depositETHValues[item.id] * globalParams.ethPrice : 0, 2);
    });

    setTotalDepositValues(newTotalDepositValues);
    setDepositValues(newDepositValues);
  }, [globalParams.ethPrice, totalDepositETHValues, depositETHValues])

  return (
    <Box>
      <FirstTitle>
        Dashboard
      </FirstTitle>
      <SecondTitle>
        Your Portfolio
      </SecondTitle>
      <TableContainer component={Paper} sx={{
        // border: 2,
        // borderRadius: '20px',
        // borderColor: 'primary.main',
        boxShadow: 0,
        background: owntheme.vars.palette.table,
        opacity: 0.85,
      }}>
        <Table component="div" sx={{ minWidth: 650 }} aria-label="positions table">
          <TableHead component="div" >
            <TableRow component="div" >
              <TableCell component="div" >
                <Typography color="primary.main" variant='subtitle1' >
                  Tokenization Pool
                </Typography>
              </TableCell>
              <TableCell component="div" align="right">
                <Typography color="primary.main" variant='subtitle1' >
                  Total Value Locked
                </Typography>
              </TableCell>
              <TableCell component="div" align="right">
                <Typography color="primary.main" variant='subtitle1' >
                  Your Deposit Value
                </Typography>
              </TableCell>
              <TableCell component="div" align="right">
                <Typography color="primary.main" variant='subtitle1' >
                  Your Claimable Point-Tokens
                </Typography>
              </TableCell>
              <TableCell component="div" align="right" />
            </TableRow>
          </TableHead>
          <TableBody component="div" sx={{bgcolor: 'background.default', opacity: 0.85}} >
            {
              poolConfig.map((item, index) => (
                <TableRow
                  hover
                  key={item.id}
                  component="div"
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                >
                  <TableCell scope="item" component={NavLink} to={`/pools/${item.id}`} sx={{textDecoration: "none"}}>
                    <Box sx={{ display: "flex", alignItems: "center" }} >
                      <img src={item.logo} height="32" style={{ marginTop: 0 }}  alt={item}/>
                      <Typography color="text.primary" variant='subtitle1' sx={{paddingLeft: '8px'}}>
                        {item.protocol} Tokenization Pool
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell component="div" align="right">
                    <Typography color="text.primary" variant='subtitle1' >
                      <CurrencyFormat value={totalDepositValues ? fixedNumber(totalDepositValues[item.id],2) : 0} displayType={'text'} thousandSeparator={true} prefix="$" />
                    </Typography>
                  </TableCell>
                  <TableCell component="div" align="right">
                    <Typography color="text.primary" variant='subtitle1' >
                      <CurrencyFormat value={depositValues ? fixedNumber(depositValues[item.id],2) : 0} displayType={'text'} thousandSeparator={true} prefix="$" />
                    </Typography>
                  </TableCell>
                  <TableCell component="div" align="right">
                    {item.rewardPoints.map((rewardToken) => (
                      <Box key={rewardToken} sx={{padding: "0px 2px" }} >
                        <Typography color="text.primary" variant='subtitle1' >
                          <CurrencyFormat value={userRewards[item.id]?.hasOwnProperty(rewardToken) ? fixedNumber(userRewards[item.id][rewardToken],2) : 0} displayType={'text'} thousandSeparator={true} suffix={` $${tokenConfig[rewardToken].symbol}`} />
                        </Typography>
                        {/*<img src={tokenConfig[rewardToken].logo} height="30" style={{ marginTop: 0 }} alt={rewardToken} />*/}
                      </Box>
                    ))}
                  </TableCell>
                  <TableCell component="div" align="right">
                    <Button variant="contained" component={NavLink} to={`/pools/${item.id}`} sx={{margin: "0px 2px"}}>
                      Tokenize Point
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <SecondTitle>
        Tokenized Point-Token Stats
      </SecondTitle>
      <TableContainer component={Paper} sx={{
        // border: 2,
        // borderRadius: '20px',
        // borderColor: 'primary.main',
        boxShadow: 0,
        background: owntheme.vars.palette.table,
        opacity: 0.85,
      }}>
        <Table component="div" sx={{ minWidth: 650 }} aria-label="tokens table">
          <TableHead component="div" >
            <TableRow component="div" >
              <TableCell component="div" >
                <Typography color="primary.main" variant='subtitle1' >
                  Point-Token Name
                </Typography>
              </TableCell>
              <TableCell component="div" align="right">
                <Typography color="primary.main" variant='subtitle1' >
                  Project
                </Typography>
              </TableCell>
              <TableCell component="div" align="right">
                <Typography color="primary.main" variant='subtitle1' >
                  Total Supply
                </Typography>
              </TableCell>
              <TableCell component="div" align="right">
                <Typography color="primary.main" variant='subtitle1' >
                  Your Balance
                </Typography>
              </TableCell>
              <TableCell component="div" align="right" />
            </TableRow>
          </TableHead>
          <TableBody component="div" sx={{bgcolor: 'background.default', opacity: 0.85}}>
            {
              pointList.map((item, index) => (
                <TableRow
                  hover
                  key={item}
                  component="div"
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                >
                  <TableCell component="div" scope="item" sx={{textDecoration: "none"}}>
                    <Box sx={{ display: "flex", alignItems: "center" }} >
                      <img src={tokenConfig[item].logo} height="32" style={{ marginTop: 0 }}  alt={item}/>
                      <Typography color="text.primary" variant='subtitle1' sx={{paddingLeft: '8px'}}>
                        {tokenConfig[item].name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell component="div" align="right">
                    <Typography color="text.primary" variant='subtitle1' >
                      {tokenConfig[item].protocol}
                    </Typography>
                  </TableCell>
                  <TableCell component="div" align="right">
                    <Typography color="text.primary" variant='subtitle1' >
                      <CurrencyFormat value={totalSupplys ? fixedNumber(totalSupplys[item],2) : 0} displayType={'text'} thousandSeparator={true} suffix={` $${tokenConfig[item].symbol}`} />
                    </Typography>
                  </TableCell>
                  <TableCell component="div" align="right">
                    <Typography color="text.primary" variant='subtitle1' >
                      <CurrencyFormat value={balances ? fixedNumber(balances[item],2) : 0} displayType={'text'} thousandSeparator={true} suffix={` $${tokenConfig[item].symbol}`} />
                    </Typography>
                  </TableCell>
                  <TableCell component="div" align="right">
                    <Button variant="contained" component={NavLink} to={`/encashment/${item}`} sx={{margin: "0px 2px"}}>
                      Check Stats
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
