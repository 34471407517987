import useContractCall from "./utils/useContractCall";
import {walletABI} from "../../assets/abis/walletABI";
import * as ethers from "@ethersproject/abi";
import {assetConfig} from "../../config/tokens";
import {convertToEtherAccuracy} from "../../utils/common";


export default function useDeposit(wallet, asset, depositAmount, strategy, referral) {
  depositAmount = convertToEtherAccuracy(depositAmount);
  const targetWalletContract = {
    address: wallet,
    abi: walletABI,
    chainId: 1337,
  };

  let functionName;
  let callParams;
  let msgValue = 0;
  if (asset === "ETH") {
    functionName = "depositETH";
    callParams = [referral];
    msgValue = depositAmount;
  } else {
    functionName = "depositAsset";
    callParams = [assetConfig[asset].address, depositAmount];
  }

  const abiCoder = new ethers.AbiCoder();
  const iface = new ethers.Interface(strategy.contracts.strategy.abi);
  const data = iface.encodeFunctionData(functionName, callParams);
  const callData = abiCoder.encode(['address', 'bytes'], [strategy.contracts.strategy.address, data]);

  const desc = "Deposit more asset."
  return useContractCall(targetWalletContract, "execute", [callData], msgValue, desc);
  // return useContractCall(targetWalletContract, "execute", callParams, msgValue, desc);
}
