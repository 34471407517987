import axios from "axios";
import {ApolloClient, gql, InMemoryCache} from "@apollo/client";
import {ZERO_ADDRESS} from "../config/constants";
import {convertBigIntToNumberWithDecimal} from "./common";

export async function getAirdropResult(address=ZERO_ADDRESS) {
  const graphURL = "https://api.thegraph.com/subgraphs/name/depointxyz/depoint-airdrop";
  const airdropQuery = `
    query {
      user(id: "${address.toLowerCase()}") {
        id
        bedrockRecord {
          stakeAmount
        }
        eigenPieRecord {
          stakeAmount
        }
        etherFiRecord {
          stakeAmount
        }
        kelpDAORecord {
          stakeAmount
        }
        pufferRecord {
          stakeAmount
        }
        renzoRecord {
          stakeAmount
        }
        swellRecord {
          stakeAmount
        }
      }
    }
  `

  const client = new ApolloClient({
    uri: graphURL,
    cache: new InMemoryCache(),
  });
  let response = await client.query({
    query: gql(airdropQuery),
  });

  let actionRewards = 0;
  let stakeRewards = 0;
  const userResult = response.data.user;
  if (userResult) {
    let actionNum = userResult.bedrockRecord.length + userResult.etherFiRecord.length + userResult.eigenPieRecord.length
      + userResult.kelpDAORecord.length + userResult.pufferRecord.length + userResult.renzoRecord.length + userResult.swellRecord.length;
    actionRewards = actionNum > 0 ? 10000 : 0;

    let stakeAmount = 0;
    if (userResult.bedrockRecord.length > 0) {
      stakeAmount += userResult.bedrockRecord[0].stakeAmount;
    }

    if (userResult.etherFiRecord.length > 0) {
      stakeAmount += userResult.etherFiRecord[0].stakeAmount;
    }

    if (userResult.eigenPieRecord.length > 0) {
      stakeAmount += userResult.eigenPieRecord[0].stakeAmount;
    }

    if (userResult.kelpDAORecord.length > 0) {
      stakeAmount += userResult.kelpDAORecord[0].stakeAmount;
    }

    if (userResult.pufferRecord.length > 0) {
      stakeAmount += userResult.pufferRecord[0].stakeAmount;
    }

    if (userResult.renzoRecord.length > 0) {
      stakeAmount += userResult.renzoRecord[0].stakeAmount;
    }

    if (userResult.swellRecord.length > 0) {
      stakeAmount += userResult.swellRecord[0].stakeAmount;
    }

    stakeAmount = convertBigIntToNumberWithDecimal(stakeAmount, 18);

    if (0 < stakeAmount && stakeAmount < 1) {
      stakeRewards = 10000;
    } else if (1 < stakeAmount && stakeAmount < 32) {
      stakeRewards = 30000;
    } else if (32 < stakeAmount) {
      stakeRewards = 50000;
    }
  }

  const medialTask = "https://api.sograph.xyz/api/open/campaign/zfGmu48XmP/candidates?api-key=682380C28CAA4B34872F0AEB2A2C2D78";
  let isDouble = false;
  try {
    const result = await axios.get(medialTask);
    result.data.data.candidates.every((item) => {
        if (item.address.toLowerCase() === address.toLowerCase()) {
          isDouble = true;
          return false;
        }
        return true;
    });
  } catch (error) {
    console.log('Failed to fetch Port3 Result.');
  }
  return {
    actionRewards: actionRewards,
    stakeRewards: stakeRewards,
    isDouble: isDouble
  };
}
