import React, {useEffect, useState} from "react";
import styled from 'styled-components'
import {NavLink} from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Logo from "../../assets/imgs/Logo.png";
import Toolbar from "@mui/material/Toolbar";
import {Button, Container, useColorScheme} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {MaterialUISwitch} from "./MaterialUISwitch";
import {ConnectButton} from "@rainbow-me/rainbowkit";
import {setThemeMode} from "../../slices/themeSlice";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import {selectReferral} from "../../slices/globalParamsSlice";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {owntheme} from "../../themes";
import Marquee from "react-fast-marquee";
import {useLocation} from "react-router";

export default function Nav() {
  // const { mode, setMode } = useColorScheme();
  const location = useLocation();
  let initTab = location.pathname === '/airdrop' ? 'four' : location.pathname === '/dashboard' ? 'three' : location.pathname === '/encashment' ? 'two' : 'one';

  const { mode, setMode } = useColorScheme();
  const referral = useSelector(selectReferral);
  const referralParam = referral ? "?referral=" + referral : "";

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [value, setValue] = useState(initTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    let tab = location.pathname === '/airdrop' ? 'four' : location.pathname === '/dashboard' ? 'three' : location.pathname === '/encashment' ? 'two' : 'one';
    setValue(tab);
  }, [location]);

  return (
    <AppBar position="sticky" sx={{background: owntheme.vars.palette.bg}}>
      <Marquee autoFill>
        <Box sx={{bgcolor: '#581C87', p: '3px 20px', textDecoration: 'None', display: "flex", alignItems: "center"}} component={NavLink} to="/airdrop">
          <Typography variant="subtitle1" sx={{padding: "0px 10px", fontWeight: 900}} color="white">
            🚀 Check Depoint Airdrop Rules 🚀
          </Typography>
        </Box>
      </Marquee>

      <Toolbar disableGutters sx={{ justifyContent: "center", display: "flex", flexDirection: 'column' }}>
        <Container
          maxWidth={false}
          sx={{ display: "flex", justifyContent: "space-between", width: "80%", minHeight: "80px" }}
        >
          <Box sx={{ display: "flex", alignItems: "center", textDecoration: 'None', color: 'black', m: '0px 50px' }} component={NavLink} to="/">
            <img src={Logo} height="35" style={{ marginTop: 0 }}  alt="Test" />
            <Typography variant="h4" sx={{padding: "0px 10px", fontWeight: 900}} color="text.primary">
              Depoint
            </Typography>
          </Box>

          <Box sx={{display: "flex", alignItems: "center"}}>
            {/*<StyledNavLink end to={"/pools" + referralParam}>*/}
            {/*  <Typography variant="h6" sx={{padding: "0px 10px", fontWeight: 800}} color="text.primary">*/}
            {/*    POOLS*/}
            {/*  </Typography>*/}
            {/*</StyledNavLink>*/}
            {/*<StyledNavLink end to={"/converts" + referralParam}>*/}
            {/*  <Typography variant="h6" sx={{padding: "0px 10px", fontWeight: 800}} color="text.primary">*/}
            {/*    CONVERTS*/}
            {/*  </Typography>*/}
            {/*</StyledNavLink>*/}
            {/*<StyledNavLink end to={"/dashboard" + referralParam}>*/}
            {/*  <Typography variant="h6" sx={{padding: "0px 10px", fontWeight: 800}} color="text.primary">*/}
            {/*    DASHBOARD*/}
            {/*  </Typography>*/}
            {/*</StyledNavLink>*/}
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"

            >
              <Tab value="one" label="Pools" component={NavLink} to={"/pools" + referralParam} sx={{ fontSize: '20px' }} />
              <Tab value="two" label="Encashment" component={NavLink} to={"/encashment" + referralParam} sx={{ fontSize: '20px' }} />
              <Tab value="three" label="Dashboard" component={NavLink} to={"/dashboard" + referralParam} sx={{ fontSize: '20px' }} />
              <Tab value="four" label="Airdrop" component={NavLink} to={"/airdrop" + referralParam} sx={{ fontSize: '20px' }} />
            </Tabs>
            <StyledButton onClick={handleClick}>
              <Typography variant="h6" sx={{padding: "0px 10px", fontWeight: 800}} color="text.secondary">
                RESOURCES
              </Typography>
              <ArrowDropDownIcon sx={{color: 'text.secondary'}} />
            </StyledButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", backgroundColor: "transparent", padding: "10px 2px"}}>
                <StyledLink href="https://docs.depoint.xyz" target="_blank" >
                  <Typography variant="h6" sx={{padding: "0px 10px", fontWeight: 800}} color="text.primary">
                    DOCS
                  </Typography>
                </StyledLink>
                <StyledLink href="https://discord.gg/fsVr9gUj" target="_blank" >
                  <Typography variant="h6" sx={{padding: "0px 10px", fontWeight: 800}} color="text.primary">
                    COMMUNITY
                  </Typography>
                </StyledLink>
                <StyledLink href="https://docs.depoint.xyz/guides/deposit-and-withdraw-assets" target="_blank" >
                  <Typography variant="h6" sx={{padding: "0px 10px", fontWeight: 800}} color="text.primary">
                    TUTORIAL
                  </Typography>
                </StyledLink>
              </Box>
            </Popover>
            {/*<StyledLink href="https://snapshot.page/#/basiscash.eth" target="_blank" >Resources</StyledLink>*/}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <StyledNav>
              {/*<Web3NetworkSwitch />*/}
              {/*/!*<FormControl sx={{ m: 1, minWidth: 120 }}>*!/*/}
              {/*/!*  <Select*!/*/}
              {/*/!*    value={network}*!/*/}
              {/*/!*    onChange={handleChange}*!/*/}
              {/*/!*    displayEmpty*!/*/}
              {/*/!*    inputProps={{ 'aria-label': 'Without label' }}*!/*/}
              {/*/!*  >*!/*/}
              {/*/!*    <MenuItem value={0}>Arbitrum</MenuItem>*!/*/}
              {/*/!*    <MenuItem value={1} disabled>Coming Soon</MenuItem>*!/*/}
              {/*/!*  </Select>*!/*/}
              {/*/!*</FormControl>*!/*/}
              {/*<Web3Button />*/}
              <ConnectButton />
              <MaterialUISwitch sx={{ m: 1 }} checked={mode !== 'light'} onClick={() => {
                if (mode === 'light') {
                  setMode('dark');
                  // setTheme(
                  //   {
                  //     themeMode: 'dark',
                  //     themeVariables: {
                  //       '--w3m-font-family': 'Roboto, sans-serif',
                  //       '--w3m-accent-color': '#F5841F'
                  //       // ...
                  //     }
                  //   }
                  // );
                  dispatch(setThemeMode("dark"));
                } else {
                  setMode('light');
                  // setTheme(
                  //   {
                  //     themeMode: 'light',
                  //     themeVariables: {
                  //       '--w3m-font-family': 'Roboto, sans-serif',
                  //       '--w3m-accent-color': '#000124'
                  //       // ...
                  //     }
                  //   }
                  // );
                  dispatch(setThemeMode("light"));
                }
              }} />
            </StyledNav>
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  )
}

const StyledNav = styled.nav`
  align-items: center;
  display: flex;
`

const StyledNavLink = styled(NavLink)`
  color: #212121;
  font-weight: 700;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  &:hover {
    color: #eeeeee;
  }
  &.active {
    color: #757575;
  }
`

const StyledButton = styled.button`
  color: #212121;
  font-size: 16px;
  font-weight: 700;
  padding-left: 10px;
  padding-right: 10px;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  border: 0;
  display: flex;
  align-items: center;

  &:hover {
    color: #eeeeee;
  }
  &.active {
    color: #757575;
  }
`

const StyledLink = styled.a`
  color: #212121;
  font-weight: 700;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  &:hover {
    color: #eeeeee;
  }
  &.active {
    color: #757575;
  }
`
