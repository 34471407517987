import { mainnet, arbitrum  } from 'wagmi/chains'

const ganache = {
  id: 1337,
  name: "Ganache",
  network: "ganache",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
  },
  walletFactory: {
    address : "",
    chainId: 1337
  },
  rpcUrls: {
    default: {
      http: ["http://localhost:8545"]
    },
    public: {
      http: ["http://localhost:8545"]
    }
  }
}

export const chainsConfig = [
  // {
  //   ...mainnet,
  //   graphURL: "http://localhost:8000/subgraphs/name/dlever-testnet-ganache",
  //   factoryAddr: "",
  // },
  // {
  //   ...arbitrum,
  //   graphURL: "http://localhost:8000/subgraphs/name/dlever-testnet-ganache",
  //   factoryAddr: "",
  // },
  mainnet,
  // {
  //   ...ganache,
  //   graphURL: "http://localhost:8000/subgraphs/name/dlever-testnet-ganache",
  //   factoryAddr: "",
  // },
]

export const chainsPublicURLConfig = {
  1: mainnet.rpcUrls.default.http[0],
  42161: arbitrum.rpcUrls.default.http[0],
  1337: ganache.rpcUrls.default.http[0],
}

export const chainsFactoryConfig = {
  1: {
    address : "",
      chainId: 1337
  },
  42161: {
    address : "",
    chainId: 1337
  },
  1337: {
    address : "",
    chainId: 1337
  },
}
