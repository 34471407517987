import {erc20ABI} from "../assets/abis/erc20ABI";
import EigenLayerPointTokenLogo from "../assets/imgs/points/EigenLayerPointToken.png";
import EtherFiPointTokenLogo from "../assets/imgs/points/EtherFiPointToken.png";
import KelpPointTokenLogo from "../assets/imgs/points/KelpPointToken.png";
import RenzoPointTokenLogo from "../assets/imgs/points/RenzoPointToken.png";
import SwellPointTokenLogo from "../assets/imgs/points/SwellPointToken.png";

import ETHLogo from "../assets/imgs/tokens/ETH.png";
import rETHLogo from "../assets/imgs/tokens/rETH.png";
import stETHLogo from "../assets/imgs/tokens/stETH.png";
import wstETHLogo from "../assets/imgs/tokens/wstETH.png";

import React from "react";
import {Box, Typography} from "@mui/material";


const tokenConfig = {
  "EigenLayerPointToken": {
    name: "Decentralized EigenLayer Point Token",
    symbol: "De-ELP",
    basePoint: "EigenLayer Point",
    protocol: "EigenLayer",
    contract: {
      address: '0x5Ab63835174dbe5a5dD3Cf34C50331E973a4fbd5',
      abi: erc20ABI,
      chainId: 1,
    },
    logo: EigenLayerPointTokenLogo,
    strategyID: "",
    rules: (
      <Box sx={{textAlign: 'left'}}>
        <Typography color="text.primary" variant='subtitle1' >
          50,000 $TiTi will be distributed to users participating in Use-to-Earn according to their proportion
        </Typography>
        <Typography color="text.primary" variant='subtitle2' >
        <ul>
          <li>10,000 to TiUSD buyers at TiTi-AMMs according to their daily TiUSD buying volume</li>
          <li>10,000 to TiTi Buyers at UniV2 TiTi-TiUSD pair according to their daily TiTi buying volume</li>
          <li>30,000 TiTi will be rewarded to TiUSD holders counting in seconds linearly</li>
        </ul>
        </Typography>
      </Box>
    ),
    rights: (
      <Box sx={{textAlign: 'left'}}>
        <Typography color="text.primary" variant='subtitle1' >
          50,000 $TiTi will be distributed to users participating in Use-to-Earn according to their proportion
        </Typography>
        <Typography color="text.primary" variant='subtitle2' >
          <ul>
            <li>10,000 to TiUSD buyers at TiTi-AMMs according to their daily TiUSD buying volume</li>
            <li>10,000 to TiTi Buyers at UniV2 TiTi-TiUSD pair according to their daily TiTi buying volume</li>
            <li>30,000 TiTi will be rewarded to TiUSD holders counting in seconds linearly</li>
          </ul>
        </Typography>
      </Box>
    ),
    website: "http://localhost:3000/converts/EigenLayerPointToken",
    twitter: "http://localhost:3000/converts/EigenLayerPointToken",
    community: "http://localhost:3000/converts/EigenLayerPointToken",
  },
  "EtherFiPointToken": {
    name: "Decentralized EtherFi Point Token",
    symbol: "De-EFP",
    protocol: "EtherFi",
    basePoint: "EtherFi Loyalty Point",
    contract: {
      address: '0xd24D6f22EF3C58211aC2F9279799E8a4513ee079',
      abi: erc20ABI,
      chainId: 1,
    },
    logo: EtherFiPointTokenLogo,
    strategyID: "0xf072e9D59648B2789b527Aab4E69D0AD9C329CED".toLowerCase(),
    rules: (
      <Box sx={{textAlign: 'left'}}>
        <Typography color="text.primary" variant='subtitle1' >
          50,000 $TiTi will be distributed to users participating in Use-to-Earn according to their proportion
        </Typography>
        <Typography color="text.primary" variant='subtitle2' >
          <ul>
            <li>10,000 to TiUSD buyers at TiTi-AMMs according to their daily TiUSD buying volume</li>
            <li>10,000 to TiTi Buyers at UniV2 TiTi-TiUSD pair according to their daily TiTi buying volume</li>
            <li>30,000 TiTi will be rewarded to TiUSD holders counting in seconds linearly</li>
          </ul>
        </Typography>
      </Box>
    ),
    rights: (
      <Box sx={{textAlign: 'left'}}>
        <Typography color="text.primary" variant='subtitle1' >
          50,000 $TiTi will be distributed to users participating in Use-to-Earn according to their proportion
        </Typography>
        <Typography color="text.primary" variant='subtitle2' >
          <ul>
            <li>10,000 to TiUSD buyers at TiTi-AMMs according to their daily TiUSD buying volume</li>
            <li>10,000 to TiTi Buyers at UniV2 TiTi-TiUSD pair according to their daily TiTi buying volume</li>
            <li>30,000 TiTi will be rewarded to TiUSD holders counting in seconds linearly</li>
          </ul>
        </Typography>
      </Box>
    ),
    website: "http://localhost:3000/converts/EigenLayerPointToken",
    twitter: "http://localhost:3000/converts/EigenLayerPointToken",
    community: "http://localhost:3000/converts/EigenLayerPointToken",
  },
  "KelpPointToken": {
    name: "Decentralized KelpDAO Point Token",
    symbol: "De-KDP",
    protocol: "KelpDAO",
    basePoint: "Kelp Miles Point",
    contract: {
      address: '0xfa381692e09Ad59581dC9787A5A7ED65d46cBc5F',
      abi: erc20ABI,
      chainId: 1,
    },
    logo: KelpPointTokenLogo,
    strategyID: "0x90BAdCDD0de8F8D610b282637f834B52d5b6B7F7".toLowerCase(),
    rules: (
      <Box sx={{textAlign: 'left'}}>
        <Typography color="text.primary" variant='subtitle1' >
          50,000 $TiTi will be distributed to users participating in Use-to-Earn according to their proportion
        </Typography>
        <Typography color="text.primary" variant='subtitle2' >
          <ul>
            <li>10,000 to TiUSD buyers at TiTi-AMMs according to their daily TiUSD buying volume</li>
            <li>10,000 to TiTi Buyers at UniV2 TiTi-TiUSD pair according to their daily TiTi buying volume</li>
            <li>30,000 TiTi will be rewarded to TiUSD holders counting in seconds linearly</li>
          </ul>
        </Typography>
      </Box>
    ),
    rights: (
      <Box sx={{textAlign: 'left'}}>
        <Typography color="text.primary" variant='subtitle1' >
          50,000 $TiTi will be distributed to users participating in Use-to-Earn according to their proportion
        </Typography>
        <Typography color="text.primary" variant='subtitle2' >
          <ul>
            <li>10,000 to TiUSD buyers at TiTi-AMMs according to their daily TiUSD buying volume</li>
            <li>10,000 to TiTi Buyers at UniV2 TiTi-TiUSD pair according to their daily TiTi buying volume</li>
            <li>30,000 TiTi will be rewarded to TiUSD holders counting in seconds linearly</li>
          </ul>
        </Typography>
      </Box>
    ),
    website: "http://localhost:3000/converts/EigenLayerPointToken",
    twitter: "http://localhost:3000/converts/EigenLayerPointToken",
    community: "http://localhost:3000/converts/EigenLayerPointToken",
  },
  "RenzoPointToken": {
    name: "Decentralized Renzo Point Token",
    symbol: "De-RZP",
    protocol: "Renzo",
    basePoint: "Renzo Point",
    contract: {
      address: '0xae4B0B107E33DBd2cEBa8E1cc3665528C2622C7d',
      abi: erc20ABI,
      chainId: 1,
    },
    logo: RenzoPointTokenLogo,
    strategyID: "0x97410511CA25124fa874A3c9E48d4b97a0Db0249".toLowerCase(),
    rules: (
      <Box sx={{textAlign: 'left'}}>
        <Typography color="text.primary" variant='subtitle1' >
          50,000 $TiTi will be distributed to users participating in Use-to-Earn according to their proportion
        </Typography>
        <Typography color="text.primary" variant='subtitle2' >
          <ul>
            <li>10,000 to TiUSD buyers at TiTi-AMMs according to their daily TiUSD buying volume</li>
            <li>10,000 to TiTi Buyers at UniV2 TiTi-TiUSD pair according to their daily TiTi buying volume</li>
            <li>30,000 TiTi will be rewarded to TiUSD holders counting in seconds linearly</li>
          </ul>
        </Typography>
      </Box>
    ),
    rights: (
      <Box sx={{textAlign: 'left'}}>
        <Typography color="text.primary" variant='subtitle1' >
          50,000 $TiTi will be distributed to users participating in Use-to-Earn according to their proportion
        </Typography>
        <Typography color="text.primary" variant='subtitle2' >
          <ul>
            <li>10,000 to TiUSD buyers at TiTi-AMMs according to their daily TiUSD buying volume</li>
            <li>10,000 to TiTi Buyers at UniV2 TiTi-TiUSD pair according to their daily TiTi buying volume</li>
            <li>30,000 TiTi will be rewarded to TiUSD holders counting in seconds linearly</li>
          </ul>
        </Typography>
      </Box>
    ),
    website: "http://localhost:3000/converts/EigenLayerPointToken",
    twitter: "http://localhost:3000/converts/EigenLayerPointToken",
    community: "http://localhost:3000/converts/EigenLayerPointToken",
  },
  "SwellPointToken": {
    name: "Decentralized Swell Point Token",
    symbol: "De-SWP",
    protocol: "Swell",
    basePoint: "Swell Pearl Point",
    contract: {
      address: '0x6a08E5b344d730B87F77c5155F7Df0891Dedf5DE',
      abi: erc20ABI,
      chainId: 1,
    },
    logo: SwellPointTokenLogo,
    strategyID: "0x2C52ddbD10806A4460Ae805696DF24D40644BB5B".toLowerCase(),
    rules: (
      <Box sx={{textAlign: 'left'}}>
        <Typography color="text.primary" variant='subtitle1' >
          50,000 $TiTi will be distributed to users participating in Use-to-Earn according to their proportion
        </Typography>
        <Typography color="text.primary" variant='subtitle2' >
          <ul>
            <li>10,000 to TiUSD buyers at TiTi-AMMs according to their daily TiUSD buying volume</li>
            <li>10,000 to TiTi Buyers at UniV2 TiTi-TiUSD pair according to their daily TiTi buying volume</li>
            <li>30,000 TiTi will be rewarded to TiUSD holders counting in seconds linearly</li>
          </ul>
        </Typography>
      </Box>
    ),
    rights: (
      <Box sx={{textAlign: 'left'}}>
        <Typography color="text.primary" variant='subtitle1' >
          50,000 $TiTi will be distributed to users participating in Use-to-Earn according to their proportion
        </Typography>
        <Typography color="text.primary" variant='subtitle2' >
          <ul>
            <li>10,000 to TiUSD buyers at TiTi-AMMs according to their daily TiUSD buying volume</li>
            <li>10,000 to TiTi Buyers at UniV2 TiTi-TiUSD pair according to their daily TiTi buying volume</li>
            <li>30,000 TiTi will be rewarded to TiUSD holders counting in seconds linearly</li>
          </ul>
        </Typography>
      </Box>
    ),
    website: "http://localhost:3000/converts/EigenLayerPointToken",
    twitter: "http://localhost:3000/converts/EigenLayerPointToken",
    community: "http://localhost:3000/converts/EigenLayerPointToken",
  }
}

const assetConfig = {
  "ETH": {
    name: "Ethereum",
    symbol: "ETH",
    address: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    logo: ETHLogo,
  },
  "stETH": {
    name: "Lido staking Ethereum",
    symbol: "stETH",
    address: "0x32C072916F946faBbAC7C8b444E1D2a0A958B062",
    logo: stETHLogo,
  },
  "wstETH": {
    name: "Wrapped lido staking Ethereum",
    symbol: "wstETH",
    address: "0x32C072916F946faBbAC7C8b444E1D2a0A958B062",
    logo: wstETHLogo,
  },
  "rETH": {
    name: "Rocket Ethereum",
    symbol: "rETH",
    address: "0x32C072916F946faBbAC7C8b444E1D2a0A958B062",
    logo: rETHLogo,
  }
}

const pointList = [
  "EigenLayerPointToken", "EtherFiPointToken", "KelpPointToken", "RenzoPointToken", "SwellPointToken"
];

const assetList = [
  "stETH", "wstETH", "rETH"
];

export {tokenConfig, assetConfig, pointList, assetList};
