import React, {useEffect, useState} from "react";
import {Box, Button, Divider} from "@mui/material";
import Logo from "../../assets/imgs/Logo.png";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { NavLink } from "react-router-dom";
import SecondTitle from "../components/custom/SecondTitle";
import ThumbnailCard from "../components/custom/ThumbnailCard";
import FirstTitle from "../components/custom/FirstTitle";
import CardRow from "../components/custom/CardRow";
import CardRowText from "../components/custom/CardRowText";
import OperationCard from "../components/custom/OperationCard";
import {selectGlobalParams, selectStrategies} from "../../slices/strategiesSlice";
import {poolConfig} from "../../config/pools";
import {tokenConfig} from "../../config/tokens";
import {useContractRead, useContractReads} from "wagmi";
import {walletFactory} from "../../config/contracts";
import {selectReferral} from "../../slices/globalParamsSlice";
import {convertBigIntToNumberWithDecimal, fixedNumber} from "../../utils/common";
import Typography from "@mui/material/Typography";
import CardRowTextValue from "../components/custom/CardRowTextValue";
import * as CurrencyFormat from 'react-currency-format';
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import {owntheme} from "../../themes";
import CloseIcon from '@mui/icons-material/Close';
import eigenLayerLogo from "../../assets/imgs/protocols/EigenLayer.png";

export default function Pools() {
  const globalParams = useSelector(selectGlobalParams);
  const strategiesInfo = useSelector(selectStrategies);
  const referral = useSelector(selectReferral);
  const referralParam = referral ? "?referral=" + referral : "";

  let newState = {};
  poolConfig.forEach((item) => {
    newState[item.id] = 0;
  });

  const [internalTVLs, setInternalTVLs] = useState(newState);
  const [internalTVLETHValues, setInternalTVLETHValues] = useState(newState);
  const [internalTVLValues, setInternalTVLValues] = useState(newState);
  const [globalTotalManagedValue, setGlobalTotalManagedValue] = useState(0);
  const [globalTotalUsers, setGlobalTotalUsers] = useState(0);

  useContractReads({
    contracts: poolConfig.map((item) => ({
      ...item.contracts.strategy,
      functionName: 'getTotalStakedValue',
    })),
    watch: true,
    allowFailure: true,
    onSuccess(data) {
      let newInternalTVLValues = {...newState};

      poolConfig.forEach((item,index) => {
        if (data[index].status === 'success') {
          newInternalTVLValues[item.id] = convertBigIntToNumberWithDecimal(data[index].result, 18);
        }
      });

      setInternalTVLETHValues(newInternalTVLValues);
    },
  });

  useContractReads({
    contracts: poolConfig.map((item) => ({
      ...item.contracts.strategy,
      functionName: 'totalStakedAmount',
    })),
    watch: true,
    allowFailure: true,
    onSuccess(data) {
      let newInternalTVLs = {...newState};
      poolConfig.forEach((item,index) => {
        if (data[index].status === 'success') {
          newInternalTVLs[item.id] = convertBigIntToNumberWithDecimal(data[index].result, 18);
        } else {
          newInternalTVLs[item.id] = 0;
        }
      });

      setInternalTVLs(newInternalTVLs);
    },
  });

  useContractReads({
    contracts: [{
      ...walletFactory,
      functionName: 'totalWallets',
    }],
    watch: true,
    allowFailure: true,
    onSuccess(data) {
      if (data[0].status === 'success') {
        setGlobalTotalUsers(Number(data[0]));
      }
    }
  });

  useEffect(() => {
    let newInternalTVLValues = {};
    let newGlobalTotalManagedValue = 0;
    poolConfig.forEach((item,index) => {
      newInternalTVLValues[item.id] = fixedNumber(internalTVLETHValues[item.id] * globalParams.ethPrice, 2);
      newGlobalTotalManagedValue += newInternalTVLValues[item.id];
    });

    setInternalTVLValues(newInternalTVLValues);
    setGlobalTotalManagedValue(fixedNumber(newGlobalTotalManagedValue, 2));
  }, [globalParams.ethPrice, internalTVLETHValues])

  return (
    <Box>
      <FirstTitle>
        Point Tokenization Pools
      </FirstTitle>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography color="text.primary" variant='subtitle1' sx={{width: '60%'}} >
          Depoint is a trustless Decentralized Protocol that strives to tokenize and circulate your earned points from web3 projects as more of them are embracing Points.
          Depoint implements the distribution mechanism of the target web3 off-chain point through decentralized solution, and elegantly handles the mapping relationship between Point-token on-chain and Point off-chain, so that holding Point-Token can obtain the same rights and interests as obtaining native Point, such as obtaining airdrops or IDO quota. In this way, the liquidity of Point is released and its intrinsic value is better reflected.
        </Typography>
        <ThumbnailCard title="Depoint Total Value Locked" value={
          <Typography color="text.primary" variant='h4' >
            <CurrencyFormat value={globalTotalManagedValue} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          </Typography>
        } />
        {/*<ThumbnailCard title="Total Supported Protocol" value={*/}
        {/*  <Typography color="text.primary" variant='h5' >*/}
        {/*    {poolConfig.length}*/}
        {/*  </Typography>*/}
        {/*  // <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center"}}>*/}
        {/*  //*/}
        {/*  //   {(poolConfig.map((item) => (*/}
        {/*  //     <Box key={item.id} sx={{padding: "2px 2px" }} >*/}
        {/*  //       <img src={item.logo} height="32" style={{ marginTop: 0 }} alt={item.name} />*/}
        {/*  //     </Box>*/}
        {/*  //   )))}*/}
        {/*  // </Box>*/}
        {/*} />*/}
        {/*<ThumbnailCard title="Total Users" value={*/}
        {/*  <Typography color="text.primary" variant='h5' >*/}
        {/*    {globalTotalUsers}*/}
        {/*  </Typography>*/}
        {/*} />*/}
      </Box>
      <SecondTitle>
        Support Protocols
        <Tooltip title={
          <Typography color="white" variant='subtitle1' sx={{fontWeight: 800, p: '5px'}} >
            Want to list your interested Pools? Please contact at <Typography color="white" variant='subtitle1' sx={{fontWeight: 800}} component='a'
                                                                              href={`https://discord.gg/fsVr9gUj`}>discord</Typography>.
          </Typography>
        }>
          <IconButton>
            <InfoIcon fontSize='small' sx={{color: "text.secondary"}}/>
          </IconButton>
        </Tooltip>
      </SecondTitle>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 6 }}>
        {
          (poolConfig.map((row) => (
            <Grid key={row.id} item xs={12} sm={6} md={4} >
              <OperationCard title={row.protocol + " Tokenization Pool"}>
                {/*<Divider sx={{width: '100%', color: 'primary.second'}}>*/}
                {/*  <Chip label="Tokenizated Points" size="small" color="primary" />*/}
                {/*</Divider>*/}
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", minWidth: "350px", minHeight: "50px", padding: "20px 0px" }} >
                  <Card
                    sx={{
                      display: "flex",
                      justifyItems: "center",
                      textAlign: "center",
                      padding: "10px",
                      margin: "10px 0px",
                      border: 0,
                      borderRadius: '20px',
                      borderColor: 'primary.main',
                      boxShadow: 3,
                      // background: 'none',
                      background: owntheme.vars.palette.bg,
                      // bgcolor: 'background.logoCard',
                      opacity: 0.85,
                  }}>
                    <Box sx={{display: 'flex', alignItems: 'center', p: '0px 10px'}}>
                      <img src={row.logo} height="25" style={{ marginTop: 0 }} alt={row.name} />
                      <Typography color="text.primary" variant='h5' sx={{fontWeight: 800, textAlign: 'right', p: '0px 5px'}} >
                        {row.protocol}
                      </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                      <CloseIcon />
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center', p: '0px 10px'}}>
                      <img src={Logo} height="25" style={{ marginTop: 0 }} alt={row.name} />
                      <Typography color="text.primary" variant='h5' sx={{fontWeight: 800, textAlign: 'right', p: '0px 5px'}} >
                        Depoint
                      </Typography>
                    </Box>
                  </Card>
                </Box>
                <Divider sx={{width: '100%', color: 'primary.second'}}>
                  <Chip label="Basic Info" size="small" color="primary" />
                </Divider>
                <CardRow>
                  <CardRowText>Target Protocol: </CardRowText>
                  <CardRowTextValue>{row.protocol}</CardRowTextValue>
                </CardRow>
                <CardRow>
                  <CardRowText>Points to be tokenized: </CardRowText>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: "20px", paddingTop: "10px", paddingBottom: '10px'}}>
                    {(row.rewardPoints.map((item) => (
                      <Box key={item} sx={{padding: "2px 2px" }} >
                        <img src={tokenConfig[item].logo} height="32" style={{ marginTop: 0 }} alt={row.name} />
                      </Box>
                    )))}
                    <Tooltip title={
                      <Typography color="white" variant='subtitle1' sx={{fontWeight: 800, p: '5px'}} >
                        By participating in this pool, you can obtain two types of Point-Token, including {row.rewardPoints[0]} and {row.rewardPoints[1]}.
                      </Typography>
                    }>
                      <IconButton>
                        <InfoIcon fontSize='small' sx={{color: "text.secondary"}}/>
                      </IconButton>
                    </Tooltip>
                  </Box>
                </CardRow>
                <CardRow>
                  <CardRowText>Network: </CardRowText>
                  <CardRowTextValue>{row.network}</CardRowTextValue>
                </CardRow>
                <Divider sx={{width: '100%', color: 'primary.second'}}>
                  <Chip label="Supported Asset Info" size="small" color="primary" />
                </Divider>
                <CardRow>
                  <CardRowText>Target Protocol TVL: </CardRowText>
                  <CardRowTextValue>
                    <CurrencyFormat value={strategiesInfo[row.id].externalTVL} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                  </CardRowTextValue>
                </CardRow>
                <CardRow>
                  <CardRowText>Current Pool Deposit: </CardRowText>
                  <CardRowTextValue>{internalTVLs[row.id]} ETH (<CurrencyFormat value={internalTVLValues[row.id]} displayType={'text'} thousandSeparator={true} prefix={'$'} />)</CardRowTextValue>
                </CardRow>
                {/*<CardRow>*/}
                {/*  <SmallInfoCard width="100%" backgroundColor="#FFDFDF" title="Total Value Locked: ">*/}
                {/*    {strategiesInfo[row.id].externalTVL}*/}
                {/*  </SmallInfoCard>*/}
                {/*</CardRow>*/}
                {/*<CardRow>*/}
                {/*  <SmallInfoCard width="100%" backgroundColor="#FFDFDF" title="Depoint Managed Value: ">*/}
                {/*    {internalTVLs[row.id]} ETH ({internalTVLValues[row.id]})*/}
                {/*  </SmallInfoCard>*/}
                {/*</CardRow>*/}
                <Divider sx={{width: '100%'}} />
                <CardRow>
                  <Button variant="contained" sx={{ width: "100%", margin: "20px 0px" }} component={NavLink} to={`/pools/${row.id}` + referralParam}>
                    Deposit to tokenize Point
                  </Button>
                </CardRow>
              </OperationCard>
            </Grid>
          )))
        }
      </Grid>
    </Box>
  )
}
