import React from "react";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Alert from "@mui/material/Alert";
import {useDispatch, useSelector} from "react-redux";
import {setClose} from "../../slices/txPayloadSlice";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "30%",
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function CustomTxAlert() {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setClose());
  };

  const txHash = useSelector((state) => state.txPayload.txHash);
  const status = useSelector((state) => state.txPayload.status);
  const desc = useSelector((state) => state.txPayload.desc);
  const alertType = useSelector((state) => state.txPayload.alertType);


  return (
    <Modal
      open={status}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Alert
        severity={alertType}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ ...style, mb: 2 }}
      >
        {desc} {txHash}
      </Alert>
    </Modal>
  )
}
