import React from "react";
import styled from 'styled-components'
import {Box, Container} from "@mui/material";
import Logo from "../../assets/imgs/Logo.png";
import Typography from "@mui/material/Typography";

export default function Footer() {
  return (
    <Container maxWidth={false} disableGutters sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "50px",
      bgcolor: "background.default"
    }}>
      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "30px 0px",
        width: "70%",
      }}>
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", m: "10px 0px" }}>
            <img src={Logo} height="32" style={{ marginTop: 0 }}  alt="Test"/>
            <StyledLink href="/">
              <Typography color="text.primary" variant='h6' >
                Depoint Protocol
              </Typography>
            </StyledLink>
          </Box>
          <Typography color="text.primary" variant='subtitle1' >
            ©2024 Depoint Protocol. All Rights Reserved.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <StyledLink href="https://twitter.com/depointxyz" target="_blank" >
            <Typography color="text.primary" variant='h6' >
              X
            </Typography>
          </StyledLink>
          <StyledLink href="https://github.com/depoint-xyz/depoint-protocol" target="_blank" >
            <Typography color="text.primary" variant='h6' >
              Github
            </Typography>
          </StyledLink>
          <StyledLink href="https://discord.gg/Zm3xxQH4X8" target="_blank" >
            <Typography color="text.primary" variant='h6' >
              Discord
            </Typography>
          </StyledLink>
          <StyledLink href="https://docs.depoint.xyz" target="_blank" >
            <Typography color="text.primary" variant='h6' >
              Doc
            </Typography>
          </StyledLink>
        </Box>
      </Box>
    </Container>
  )
}

const StyledLink = styled.a`
  color: #212121;
  font-weight: 700;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  &:hover {
    color: #eeeeee;
  }
  &.active {
    color: #757575;
  }
`
