import { createSlice } from '@reduxjs/toolkit'

export const txPayloadSlice = createSlice({
  name: 'txPayload',
  initialState: {
    txHash: "",
    status: false,
    desc: "",
    alertType: "info",
  },
  reducers: {
    setTxPayload: (state, action) => {
      if (action.payload.status !== "idle" && action.payload.status !== "loading") {
        console.log(action)
        state.txHash = action.payload.txHash;
        state.desc = action.payload.status === "error" ? "this is an error transaction" : "this is an success transaction";
        state.alertType = action.payload.status === "error" ? "error" : "success";
        state.status = true;
      }
    },

    setClose: (state) => {
      state.status = false;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setTxPayload, setClose } = txPayloadSlice.actions
export const selectLastTXHash = (state) => state.txPayload.txHash;

export default txPayloadSlice.reducer
