import React, {useEffect, useState} from "react";
import {Box, Button, Divider, FormControl, InputLabel, OutlinedInput} from "@mui/material";
import SecondTitle from "../components/custom/SecondTitle";
import ThumbnailCard from "../components/custom/ThumbnailCard";
import FirstTitle from "../components/custom/FirstTitle";
import CardRow from "../components/custom/CardRow";
import CardRowText from "../components/custom/CardRowText";
import OperationCard from "../components/custom/OperationCard";
import {useAccount} from "wagmi";
import Typography from "@mui/material/Typography";
import CardRowTextValue from "../components/custom/CardRowTextValue";
import * as CurrencyFormat from 'react-currency-format';
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import AirdropModal from "../components/AirdropModal";
import {getAirdropResult} from "../../utils/strategyInteraction";
import {ZERO_ADDRESS} from "../../config/constants";
import {isAddress} from "ethers/lib/utils";
import InfoCard from "../components/custom/InfoCard";
import Tab from "@mui/material/Tab";
import {Link, NavLink} from "react-router-dom";
import Tabs from "@mui/material/Tabs";

export default function Airdrop() {
  const { address } = useAccount();
  const [isOpenAirdropModal, setIsOpenAirdropModal] = useState(false);

  const [totalAirdropAmount, setTotalAirdropAmount] = useState(0);
  const [airdropDetail, setAirdropDetail] = useState({
    interactionRewards: 0,
    restakingRewards: 0,
    daoRewards: 0,
    isBoost: false,
    totalRewards: 0,
  })
  const [checkedAddress, setCheckedAddress] = useState('');
  const [checkedAirdropAmount, setCheckedAirdropAmount] = useState(0);
  const [value, setValue] = useState('one');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (address) {
      setCheckedAddress(address);
    }
    getAirdropResult(address).then(r => {
      let result = {
        interactionRewards: r.isDouble ? r.actionRewards * 1.5 : r.actionRewards,
        restakingRewards: r.isDouble ? r.stakeRewards * 1.5 : r.stakeRewards,
        daoRewards: r.isDouble ? 5000 : 0,
        isBoost: r.isDouble,
      };

      setAirdropDetail({
        ...result,
        totalRewards: result.interactionRewards + result.restakingRewards + result.daoRewards,
      })

      setTotalAirdropAmount(result.interactionRewards + result.restakingRewards + result.daoRewards);
    });
  }, [address])

  return (
    <Box>
      <FirstTitle>
        Depoint Airdrop
      </FirstTitle>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography color="text.primary" variant='subtitle1' sx={{width: '60%'}} >
          Depoint’s goal is to make itself the most influential one in the Restake sub-DAO community and allow all DAO participants  to directly enjoy the various benefits of Restake Verticals. The governance token of Depoint's DAO is Depoint Governance Token, the symbol is $DP, and the max supply is 10 billion. For details, please refer to Depoint's Tokenomics. Depoint DAO plans to allocate 12% of the max supply to community members to reach consensus in a fair way as follows.        </Typography>
        <ThumbnailCard title="Your Airdrop Amount" value={
          <Typography color="text.primary" variant='h4' >
            <CurrencyFormat value={totalAirdropAmount} displayType={'text'} thousandSeparator={true} suffix={' $DP'} />
          </Typography>
        } />
      </Box>
      <Divider sx={{m: '20px', border: 0}}/>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
        sx={{
          width: '100%'
        }}
      >
        <Tab value="one" label="Wave1: Genesis Airdrop" sx={{ fontSize: '30px', width: '33%' }} />
        <Tab value="two" label="Wave2: Kick-off Airdrop" sx={{ fontSize: '30px', width: '33%' }} />
        <Tab value="three" label="Wave3: DAO Establishment Airdrop" sx={{ fontSize: '30px', width: '33%' }} />
      </Tabs>

      <Divider sx={{m: '20px', border: 0}}/>
      {
        value === "one" ? <Box sx={{display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: 'column', width: '100%'}}>
          <Box sx={{display: "flex", justifyContent: "center", flexDirection: 'column', width: '100%'}}>
            <OperationCard title="Check Genesis Airdrop Amount">
              <Divider sx={{p: '20px 0px'}} />
              <CardRow>
                <CardRowText>Enter Address: </CardRowText>
                <Box sx={{width: '70%', display: 'flex', justifyContent: 'right'}}>
                  <FormControl fullWidth sx={{ m: "0px 20px", width: "70%" }}>
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Input your check address
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      label="Input your check address"
                      value={checkedAddress}
                      // defaultValue="0x..."
                      onChange={(event) => {
                        setCheckedAddress(event.target.value);
                      }}
                    />
                  </FormControl>
                  <Button
                    variant={ false ? "contained" : "outlined"}
                    disabled={!isAddress(checkedAddress)}
                    onClick={() => {
                      getAirdropResult(checkedAddress).then(r => {
                        let result = {
                          interactionRewards: r.isDouble ? r.actionRewards * 1.5 : r.actionRewards,
                          restakingRewards: r.isDouble ? r.stakeRewards * 1.5 : r.stakeRewards,
                          daoRewards: r.isDouble ? 5000 : 0,
                          isBoost: r.isDouble,
                        };
                        let totalRewards = result.interactionRewards + result.restakingRewards + result.daoRewards;

                        setAirdropDetail({
                          ...result,
                          totalRewards: result.interactionRewards + result.restakingRewards + result.daoRewards,
                        })

                        setCheckedAirdropAmount(totalRewards);
                        setIsOpenAirdropModal(true);
                      });
                    }}
                  >Check Your Airdrop Amount</Button>
                </Box>
              </CardRow>
              <CardRow>
                <TableContainer component={Paper} sx={{
                  // border: 2,
                  // borderRadius: '20px',
                  // borderColor: 'primary.main',
                  boxShadow: 0,
                  // background: owntheme.vars.palette.table,
                  opacity: 0.85,
                }}>
                  <Table component="div" sx={{ minWidth: 650 }} aria-label="positions table">
                    <TableHead component="div" >
                      <TableRow component="div" >
                        <TableCell component="div" >
                          <Typography color="primary.main" variant='subtitle1' >
                            Airdrop Availability
                          </Typography>
                        </TableCell>
                        <TableCell component="div" align="right">
                          <Typography color="primary.main" variant='subtitle1' >
                            Qualification
                          </Typography>
                        </TableCell>
                        <TableCell component="div" align="right">
                          <Typography color="primary.main" variant='subtitle1' >
                            ️Boosted
                          </Typography>
                        </TableCell>
                        <TableCell component="div" align="right">
                          <Typography color="primary.main" variant='subtitle1' >
                            Your Claimable Airdrop Amount
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody component="div" sx={{bgcolor: 'background.default', opacity: 0.85}} >
                      <TableRow
                        hover
                        key='rule1'
                        component="div"
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                      >
                        <TableCell component="div" sx={{textDecoration: "none"}}>
                          <Box sx={{ display: "flex", alignItems: "center" }} >
                            <Typography color="text.primary" variant='subtitle1' sx={{paddingLeft: '8px'}}>
                              Retrospective Interaction
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell component="div" align="right">
                          <Typography color="text.primary" variant='subtitle1' >
                            {airdropDetail.interactionRewards > 0 ? "Yes" : "No"}
                          </Typography>
                        </TableCell>
                        <TableCell component="div" align="right">
                          <Typography color="text.primary" variant='subtitle1' >
                            {airdropDetail.isBoost ? "Yes" : "No"}
                          </Typography>
                        </TableCell>
                        <TableCell component="div" align="right">
                          <Typography color="text.primary" variant='subtitle1' >
                            <CurrencyFormat value={airdropDetail.interactionRewards} displayType={'text'} thousandSeparator={true} suffix={' $DP'} />
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        hover
                        key='rule2'
                        component="div"
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                      >
                        <TableCell component="div" sx={{textDecoration: "none"}}>
                          <Box sx={{ display: "flex", alignItems: "center" }} >
                            <Typography color="text.primary" variant='subtitle1' sx={{paddingLeft: '8px'}}>
                              Retrospective Restaking Amount
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell component="div" align="right">
                          <Typography color="text.primary" variant='subtitle1' >
                            {airdropDetail.restakingRewards > 0 ? "Yes" : "No"}
                          </Typography>
                        </TableCell>
                        <TableCell component="div" align="right">
                          <Typography color="text.primary" variant='subtitle1' >
                            {airdropDetail.isBoost > 0 ? "Yes" : "No"}
                          </Typography>
                        </TableCell>
                        <TableCell component="div" align="right">
                          <Typography color="text.primary" variant='subtitle1' >
                            <CurrencyFormat value={airdropDetail.restakingRewards} displayType={'text'} thousandSeparator={true} suffix={' $DP'} />
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        hover
                        key='rule3'
                        component="div"
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                      >
                        <TableCell component="div" sx={{textDecoration: "none"}}>
                          <Box sx={{ display: "flex", alignItems: "center" }} >
                            <Typography color="text.primary" variant='subtitle1' sx={{paddingLeft: '8px'}}>
                              DAO Contribution
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell component="div" align="right">
                          <Typography color="text.primary" variant='subtitle1' >
                            {airdropDetail.daoRewards > 0 ? "Yes" : "No"}
                          </Typography>
                        </TableCell>
                        <TableCell component="div" align="right">
                          <Typography color="text.primary" variant='subtitle1' >
                            ---
                          </Typography>
                        </TableCell>
                        <TableCell component="div" align="right">
                          <Typography color="text.primary" variant='subtitle1' >
                            <CurrencyFormat value={airdropDetail.daoRewards} displayType={'text'} thousandSeparator={true} suffix={' $DP'} />
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        hover
                        key='rule4'
                        component="div"
                        sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                      >
                        <TableCell component="div" sx={{textDecoration: "none"}}>
                          <Box sx={{ display: "flex", alignItems: "center" }} >
                            <Typography color="text.primary" variant='subtitle1' sx={{paddingLeft: '8px'}}>
                              Total
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell component="div" align="right" />
                        <TableCell component="div" align="right" />
                        <TableCell component="div" align="right">
                          <Typography color="text.primary" variant='subtitle1' >
                            <CurrencyFormat value={airdropDetail.totalRewards} displayType={'text'} thousandSeparator={true} suffix={' $DP'} />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardRow>
            </OperationCard>
          </Box>
          <Box sx={{display: "flex", justifyContent: "center", flexDirection: 'column', width: '100%'}}>
            <OperationCard title="Airdrop Explained">
              <CardRow>
                <CardRowText>Allocation: </CardRowText>
                <CardRowTextValue>6% of Max Supply</CardRowTextValue>
              </CardRow>
              <CardRow>
                <CardRowText>Starting & Ending Time: </CardRowText>
                <CardRowTextValue>Lasting 3 weeks  from March 18th to April 4th</CardRowTextValue>
              </CardRow>
              <CardRow>
                <CardRowText>Claim Time: </CardRowText>
                <CardRowTextValue>00:00:00 UTC AM April 5th, 2024</CardRowTextValue>
              </CardRow>
              <CardRow>
                <CardRowText>Claim Rules: </CardRowText>
                <CardRowTextValue>First come first served after TGE</CardRowTextValue>
              </CardRow>
              <CardRow>
                <CardRowText>FAQ: </CardRowText>
                <CardRowTextValue>
                  <Typography color="white" variant='subtitle1' sx={{fontWeight: 800}} component='a'
                              href={`https://medium.com/@depointxyz/airdrop-c7eb0774eb45`}>Learn about.</Typography>
                </CardRowTextValue>
              </CardRow>
              <Box sx={{ display: "flex", alignItems: "start", justifyContent: "space-between", minHeight: "50px", padding: "0px 15px", margin: "5px 0px", width: '95%'}}>
                <InfoCard width="45%" backgroundColor="#FFDFDF" title="Retrospective Airdrop">
                  <Divider sx={{width: '100%', color: 'primary.second', p: '20px 0px'}} >
                    <Chip label="1: Restaking Interaction" size="medium" color="primary" />
                  </Divider>
                  <CardRow>
                    <CardRowText>Allocation: </CardRowText>
                    <CardRowTextValue>2% of Max Supply</CardRowTextValue>
                  </CardRow>
                  <CardRow>
                    <CardRowText>Airdrop Amount: </CardRowText>
                    <CardRowTextValue>5000 $DP</CardRowTextValue>
                  </CardRow>
                  <CardRow>
                    <CardRowText>Qualification: </CardRowText>
                    <CardRowTextValue>Address must have restaked ETH before snapshot time in target LRT projects, <Typography color="white" variant='subtitle1' sx={{fontWeight: 800}} component='a'
                                                                                                                              href={`https://medium.com/@depointxyz/airdrop-c7eb0774eb45`}>learn more.</Typography></CardRowTextValue>
                  </CardRow>
                  <CardRow>
                    <CardRowText>Snapshot: </CardRowText>
                    <CardRowTextValue> 00:00:00 AM UTC March 1st, 2024</CardRowTextValue>
                  </CardRow>
                  <Divider sx={{width: '100%', color: 'primary.second', p: '20px 0px'}} >
                    <Chip label="2: Restaking Amount" size="medium" color="primary" />
                  </Divider>
                  <CardRow>
                    <CardRowText>Allocation: </CardRowText>
                    <CardRowTextValue>3.5% of Max Supply</CardRowTextValue>
                  </CardRow>
                  <CardRow>
                    <CardRowText>Airdrop Amount: </CardRowText>
                    <CardRowTextValue>Range from 10,000 to 50,000 $DP</CardRowTextValue>
                  </CardRow>
                  <CardRow>
                    <CardRowText>Qualification: </CardRowText>
                    <CardRowTextValue>
                      Based on address's ETH Restaking Amount at Snapshot, <Typography color="white" variant='subtitle1' sx={{fontWeight: 800}} component='a'
                                                                                         href={`https://medium.com/@depointxyz/airdrop-c7eb0774eb45`}>learn more.</Typography>
                    </CardRowTextValue>
                  </CardRow>
                  <CardRow>
                    <CardRowText>Snapshot: </CardRowText>
                    <CardRowTextValue>00:00:00 AM UTC March 1st, 2024</CardRowTextValue>
                  </CardRow>
                </InfoCard>
                <InfoCard width="45%" backgroundColor="#FFDFDF" title="Early DAO Contribution Airdrop">
                  <Divider sx={{width: '100%', color: 'primary.second', p: '20px 0px'}} >
                    <Chip label="Early DAO contributors" size="medium" color="primary" />
                  </Divider>
                  <CardRow>
                    <Typography color="text.secondary" variant='subtitle1' sx={{fontWeight: 800, textAlign: 'left'}} >
                      Apart from Retrospective Airdrop, Depoint set aside 50 million $DP for first 10k users who haven't been able to restake previously, yet are interested to become DAO members. Depoint got your covered, earn this share by completing <Typography color="white" variant='subtitle1' sx={{fontWeight: 800}} component='a'
                                                                                                                                                                                                                                                                        href={`https://soquest.xyz/space/depoint`}>👉 DAO Contribution Tasks 👈.</Typography>
                    </Typography>
                  </CardRow>
                  <CardRow>
                    <CardRowText>Task Period: </CardRowText>
                    <CardRowTextValue>March 18th to April 5th</CardRowTextValue>
                  </CardRow>
                  <CardRow>
                    <CardRowText>Qualification: </CardRowText>
                    <CardRowTextValue> Top 10k community members completed task. It's recommend that you came back and check, just in case.</CardRowTextValue>
                  </CardRow>
                  <Divider sx={{width: '100%', color: 'primary.second', p: '20px 0px'}} >
                    <Chip label="Airdrop Booster: 50% Extra For Retrospective qualifier (Optional)" size="medium" color="primary" />
                  </Divider>
                  <CardRow>
                    <Typography color="text.secondary" variant='subtitle1' sx={{fontWeight: 800, textAlign: 'left'}} >
                      The cherry on top for those who are qualified for retrospective airdrop, is that if you complete DAO Early Contribution Tasks and you can earn an extra 50% of the whole amount based on the snapshot. The maximum airdrop amount can be upto 82,500 $DP.
                    </Typography>
                  </CardRow>
                  <CardRow>
                    <Typography color="text.secondary" variant='subtitle1' sx={{fontWeight: 800, textAlign: 'left', mt: '15px'}} >
                      For example, if you are qualified for 15,000 retrospective airdrop, after completing the tasks, your airdrop amount would be boosted, 15,000 * 1.5, that's 22,500 $DP in total.
                    </Typography>
                  </CardRow>
                  <CardRow>
                    <Button variant="outlined" sx={{ width: "100%", margin: "20px 0px" }} component={Link} to="https://soquest.xyz/space/depoint">
                      Boost Your Airdrop
                    </Button>
                  </CardRow>
                </InfoCard>
              </Box>
            </OperationCard>
          </Box>
        </Box> : value === "two" ? <SecondTitle>
          Coming Soon!
        </SecondTitle> : <SecondTitle>
          Coming Soon!
        </SecondTitle>
      }

      <AirdropModal amount={checkedAirdropAmount} isBoost={airdropDetail.isBoost} status={isOpenAirdropModal} setStatus={setIsOpenAirdropModal} />
    </Box>
  )
}
