import React from "react";
import Modal from "@mui/material/Modal";
import CoinRain from "./CoinRain";
import GiftBox from "./GiftBox";
import {Box, Button} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grow from '@mui/material/Grow';
import * as CurrencyFormat from "react-currency-format";
import {tokenConfig} from "../../config/tokens";
import CardRowTextValue from "./custom/CardRowTextValue";
import {Link} from "react-router-dom";

const style = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  border: '0px solid #000',
  // boxShadow: 24,
  p: 4,
};

export default function AirdropModal({amount=0, isBoost, status, setStatus}) {
  const handleClose = () => {
    setStatus(false);
  };

  return (
    <Modal
      open={status}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <CoinRain />
        <GiftBox />
        <Grow in={true}>
          <Box  sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography color="primary.airdropModal" variant='h4' sx={{textAlign: 'center'}}>
              Your Claimable Airdrop:
            </Typography>
            <Typography color="white" variant='h3' sx={{textAlign: 'center', m: '30px 0px'}}>
              <CurrencyFormat value={amount} displayType={'text'} thousandSeparator={true} suffix=" $DP" />
            </Typography>

            {
              amount === 0 ? <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Typography color="primary.airdropModal" variant='h6' sx={{textAlign: 'center'}}>
                  Go to complete <Typography color="primary.airdropModal" variant='h6' sx={{textAlign: 'center'}} component='a'
                                                                           href={`https://soquest.xyz/space/depoint`}>DAO contribution task</Typography> and check again.
                </Typography>
                <Button variant="contained" sx={{ width: "60%", margin: "20px 0px" }}  component={Link} to="https://soquest.xyz/space/depoint">
                  Complete Tasks
                </Button>
              </Box> : !isBoost ?
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Typography color="primary.airdropModal" variant='h6' sx={{textAlign: 'center'}}>
                  Want to boost your airdrop by 150%, complete <Typography color="primary.airdropModal" variant='h6' sx={{textAlign: 'center'}} component='a'
                                                                           href={`https://soquest.xyz/space/depoint`}>DAO contribution task</Typography> and check again.
                  </Typography>
                  <Button variant="contained" sx={{ width: "60%", margin: "20px 0px" }}  component={Link} to="https://soquest.xyz/space/depoint">
                    Boost Your Airdrop
                </Button>
              </Box> : <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Typography color="primary.airdropModal" variant='h6' sx={{textAlign: 'center'}}>
                  Congrats, you've boosted your airdrop. Follow us on X or Discord for official claim time announcement.Congrats, you've boosted your airdrop. Follow us on X or Discord for official claim time announcement.
                </Typography>
              </Box>
            }

          </Box>
        </Grow>
      </Box>
    </Modal>
  )
}
