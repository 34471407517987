import React from "react";
import useFetchStrategiesInfo from "../../hooks/reads/useFetchStrategiesInfo";
import {useConnect} from "wagmi";
import {useSelector} from "react-redux";
import {selectLastTXHash} from "../../slices/txPayloadSlice";

export default function GlobalDataUpdater({children}) {
  const connect = useConnect();
  const lashTxHash = useSelector(selectLastTXHash);

  useFetchStrategiesInfo(connect, lashTxHash);

  return (
    <div>
      {children}
    </div>
  )
}
