import {useDispatch} from "react-redux";
import {
  fetchStrategiesInfo,
} from "../../slices/strategiesSlice";
import {useEffect} from "react";

export default function useFetchStrategiesInfo(connect, lashTXHash) {
  const dispatch = useDispatch();

  useEffect(() => {
    const refreshInfo = () => {
      console.log("useFetchStrategiesInfo")
      dispatch(fetchStrategiesInfo());
    };

    refreshInfo();
    const timer = setInterval(refreshInfo, 120000);

    return () => {
      clearInterval(timer);
      console.log("clear useFetchStrategiesInfo")
    };
  }, [connect, lashTXHash, dispatch]);
}
