import { experimental_extendTheme as extendTheme} from "@mui/material/styles";
import {light} from "./light";
import {dark} from "./dark";

export const owntheme = extendTheme({
  colorSchemes: {
    dark,
    light,
  }
});
