import {colors} from "@mui/material";
import x from "../assets/imgs/lightBG.gif";

export const light = {
  palette: {
    mode: 'light',
    primary: {
      main: '#0ea50e',
      second: '#0ea50e',
      airdropModal: '#afffaf'
    },
    background: {
      default: '#ffffff',
      infoCard: '#f1f1f1',
      logoCard: '#000000',
    },
    bg: 'linear-gradient(90deg, rgba(255, 255, 255,0.8631827731092436) 0%, rgba(209, 250, 229,1) 100%)',
    table: 'linear-gradient(0deg, rgba(0,255,76,0.38699229691876746) 0%, rgba(255,255,255,0.9346113445378151) 100%)',
    imageBG: `url(${x})`,
  },
  typography: {
    fontFamily: 'Open Sans',
    h1: {
      fontFamily: 'Ubuntu Mono',
    },
    h2: {
      fontFamily: 'Ubuntu Mono',
    },
    h3: {
      fontFamily: 'Ubuntu Mono',
    },
    h4: {
      fontFamily: 'Ubuntu Mono',
    },
    h6: {
      fontFamily: 'Ubuntu Mono',
    },
    h5: {
      fontFamily: 'Ubuntu Mono',
    },
    subtitle1: {
      fontFamily: 'Ubuntu Mono',
    },
    subtitle2: {
      fontFamily: 'Ubuntu Mono',
    },
    button: {
      fontFamily: 'Ubuntu Mono',
      fontWeight: 900,
    },
    overline: {
      fontFamily: 'Ubuntu Mono',
    },
  },
}
