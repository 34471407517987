import React from "react";
import './App.css';
import '@rainbow-me/rainbowkit/styles.css';
import { WagmiConfig, configureChains, createConfig } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Page from "./pages/components/Page";
import Dashboard from "./pages/dashboard/Dashboard";
import {
  RainbowKitProvider,
  lightTheme,
  darkTheme,
  connectorsForWallets
} from "@rainbow-me/rainbowkit";
import {useColorScheme} from "@mui/material";
import CustomTxAlert from "./pages/components/CustomTxAlert";
import Pools from "./pages/pools/Pools";
import Pool from "./pages/pool/Pool";
import GlobalDataUpdater from "./pages/components/GlobalDataUpdater";
import {chainsConfig} from "./config/chains";
// import {projectId} from "./config/constants";
import {metaMaskWallet, rainbowWallet, walletConnectWallet, coinbaseWallet, coin98Wallet, okxWallet} from "@rainbow-me/rainbowkit/wallets";
import Converts from "./pages/converts/Converts";
import Convert from "./pages/convert/Convert";
import {owntheme} from "./themes";
import Airdrop from "./pages/airdrop/Airdrop";

const { chains, publicClient } = configureChains(chainsConfig, [publicProvider()]);
// const { wallets } = getDefaultWallets({
//   appName: 'Dlever',
//   projectId,
//   chains,
// });

const projectId = '55fd38a2ecbc4ece97a0a418f24e1069';

const connectors = connectorsForWallets([
  {
    groupName: 'Depoint Protocol',
    wallets: [
      metaMaskWallet({ projectId, chains,
        options: {
          shimDisconnect: true,
        }
      }), //shimDisconnect: true
      walletConnectWallet({projectId, chains}),
      coinbaseWallet({chains}),
      coin98Wallet({projectId, chains}),
      rainbowWallet({projectId, chains}),
      okxWallet({projectId, chains}),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

function App() {
  const { mode } = useColorScheme();

  let rainbowTheme = mode === 'light' ? lightTheme({
    accentColor: owntheme.vars.palette.primary.second,
    borderRadius: 'medium',
    fontStack: 'system',
  }) : darkTheme({
    accentColor: owntheme.vars.palette.primary.second,
    borderRadius: 'medium',
    fontStack: 'system',
  });

  return (
    <div>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider showRecentTransactions={true} chains={chains} theme={rainbowTheme}>
          <GlobalDataUpdater>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Page />}>
                  <Route index element={<Pools />} />
                  <Route path="pools" element={<Pools />} />
                  <Route path="pools/:id" element={<Pool />} />
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="encashment" element={<Converts />} />
                  <Route path="encashment/:id" element={<Convert />} />
                  <Route path="airdrop" element={<Airdrop />} />
                  <Route path="*" element={<Pools />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </GlobalDataUpdater>
        </RainbowKitProvider>
      </WagmiConfig>
      {/*<Web3Modal*/}
      {/*  projectId={projectId}*/}
      {/*  ethereumClient={ethereumClient}*/}
      {/*  themeVariables={{*/}
      {/*    '--w3m-font-family': 'Roboto, sans-serif',*/}
      {/*    // '--w3m-accent-color': '#000000',*/}
      {/*    '--w3m-logo-image-url': 'http://localhost:3000/static/media/Logo.b009b3c080324eaeac43.png'*/}
      {/*  }}*/}
      {/*/>*/}
      <CustomTxAlert />
    </div>
  );
}

export default App;
