import {colors} from "@mui/material";
import x from '../assets/imgs/darkBG.gif';

export const dark = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#00ff00',
      second: '#33ff33',
      airdropModal: '#afffaf'
    },
    background: {
      default: '#1c1c1c',
      infoCard: '#212121',
      logoCard: '#ffffff',
    },
    bg: 'linear-gradient(90deg, rgba(14,51,10,0.8631827731092436) 0%, rgba(0,41,12,1) 45%, rgba(0,0,0,1) 100%)',
    table: 'linear-gradient(0deg, rgba(19,0,78,0.38699229691876746) 0%, rgba(0,0,0,0.9346113445378151) 100%)',
    imageBG: `url(${x})`,
  },
  typography: {
    fontFamily: 'Open Sans',
    h1: {
      fontFamily: 'Ubuntu Mono',
    },
    h2: {
      fontFamily: 'Ubuntu Mono',
    },
    h3: {
      fontFamily: 'Ubuntu Mono',
    },
    h4: {
      fontFamily: 'Ubuntu Mono',
    },
    h6: {
      fontFamily: 'Ubuntu Mono',
    },
    h5: {
      fontFamily: 'Ubuntu Mono',
    },
    subtitle1: {
      fontFamily: 'Ubuntu Mono',
    },
    subtitle2: {
      fontFamily: 'Ubuntu Mono',
    },
    button: {
      fontFamily: 'Ubuntu Mono',
      fontWeight: 900,
    },
    overline: {
      fontFamily: 'Ubuntu Mono',
    },
  },
}
