import React from "react";
import {Box} from "@mui/material";

export default function CardRow({children}) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", minHeight: "50px", padding: "0px 15px", margin: "5px 0px", width: '95%'}} >
      {children}
    </Box>
  )
}
