import React, {useState} from "react";
import {Box, Button, Divider, Typography} from "@mui/material";
import Logo from "../../assets/imgs/Logo.png";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { NavLink } from "react-router-dom";
import SecondTitle from "../components/custom/SecondTitle";
import FirstTitle from "../components/custom/FirstTitle";
import CardRow from "../components/custom/CardRow";
import CardRowText from "../components/custom/CardRowText";
import OperationCard from "../components/custom/OperationCard";
import {selectGlobalParams, selectStrategies} from "../../slices/strategiesSlice";
import {pointList, tokenConfig} from "../../config/tokens";
import {useAccount, useContractReads} from "wagmi";
import {selectReferral} from "../../slices/globalParamsSlice";
import {convertBigIntToNumberWithDecimal, fixedNumber} from "../../utils/common";
import CardRowTextValue from "../components/custom/CardRowTextValue";
import Chip from "@mui/material/Chip";
import * as CurrencyFormat from "react-currency-format";

export default function Converts() {
  const strategiesInfo = useSelector(selectStrategies);
  const globalParams = useSelector(selectGlobalParams);
  const referral = useSelector(selectReferral);
  const referralParam = referral ? "?referral=" + referral : "";

  const { address } = useAccount();

  let newState = {};
  pointList.forEach((item) => {
    newState[item] = 0;
  });

  const [totalSupplys, setTotalSupplys] = useState(newState);
  const [balances, setBalances] = useState(newState);

  useContractReads({
    contracts: pointList.map((item) => ({
      ...tokenConfig[item].contract,
      functionName: 'totalSupply',
    })),
    watch: true,
allowFailure: true,
    onSuccess(data) {
      let newResult = {};
      pointList.forEach((item,index) => {
        if (data[index].status === 'success') {
          newResult[item] = convertBigIntToNumberWithDecimal(data[index].result, 18);
        } else {
          newResult[item] = 0;
        }
      });

      setTotalSupplys(newResult);
    },
  });

  useContractReads({
    contracts: pointList.map((item) => ({
      ...tokenConfig[item].contract,
      functionName: 'balanceOf',
      args: [address],
    })),
    watch: true,
allowFailure: true,
    onSuccess(data) {
      let newResult = {};
      pointList.forEach((item,index) => {
        if (data[index].status === 'success') {
          newResult[item] = convertBigIntToNumberWithDecimal(data[index].result, 18);
        } else {
          newResult[item] = 0;
        }
      });

      setBalances(newResult);
    },
  });

  return (
    <Box>
      <FirstTitle>
        Total Tokenizated Points
      </FirstTitle>
      <Typography color="text.primary" variant='h6' sx={{width: '100%', p: '30px 0px'}} >
        You can encashment your rights and benefits in contract level once each Project Official empowered any to the points accumulated. You are the ultimate owner of the rights and points.
      </Typography>
      <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 3, md: 6 }}>
        {
          (pointList.map((item) => (item === 'EigenLayerPointToken' ?
            <Grid key={item} item xs={12} sm={6} md={4} >
              <OperationCard width="95%%" title={tokenConfig[item].protocol}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", minWidth: "350px", minHeight: "50px", padding: "20px 0px" }} >
                  <img src={tokenConfig[item].logo} height="40" style={{ marginTop: 0 }} alt={item} />
                  <Typography color="text.primary" variant='h4' sx={{fontWeight: 800, textAlign: 'right', paddingLeft: '10px'}} >
                    {tokenConfig[item].symbol}
                  </Typography>
                </Box>
                <Divider sx={{width: '100%', color: 'primary.second', p: '20px 0px'}}>
                  <Chip label="Base Info" size="small" color="primary" />
                </Divider>
                <CardRow>
                  <CardRowText>Point-Token Name: </CardRowText>
                  <CardRowTextValue>{tokenConfig[item].name}</CardRowTextValue>
                </CardRow>
                <CardRow>
                  <CardRowText>Point-Token Symbol: </CardRowText>
                  <CardRowTextValue>${tokenConfig[item].symbol}</CardRowTextValue>
                </CardRow>
                <CardRow>
                  <CardRowText>Original Point Name: </CardRowText>
                  <CardRowTextValue>{tokenConfig[item].basePoint}</CardRowTextValue>
                </CardRow>
                <CardRow>
                  <CardRowText>Protocol Name: </CardRowText>
                  <CardRowTextValue>{tokenConfig[item].protocol}</CardRowTextValue>
                </CardRow>
                <CardRow>
                  <CardRowText>Supported Asset: </CardRowText>
                  <CardRowTextValue>ETH</CardRowTextValue>
                </CardRow>
                <Divider sx={{width: '100%', color: 'primary.second', p: '20px 0px'}}>
                  <Chip label="Point Token Info" size="small" color="primary" />
                </Divider>
                <CardRow>
                  <CardRowText>Total Supply: </CardRowText>
                  <CardRowTextValue>
                    <CurrencyFormat value={fixedNumber(totalSupplys[item], 2)} displayType={'text'} thousandSeparator={true} suffix={` \$${tokenConfig[item].symbol}`} />
                  </CardRowTextValue>
                </CardRow>
                <CardRow>
                  <CardRowText>Your Balance: </CardRowText>
                  <CardRowTextValue>
                    <CurrencyFormat value={fixedNumber(balances[item], 2)} displayType={'text'} thousandSeparator={true} suffix={` \$${tokenConfig[item].symbol}`} />
                  </CardRowTextValue>
                </CardRow>
                <CardRow>
                  <CardRowText>Encashment Status: </CardRowText>
                  <CardRowTextValue>Not Yet</CardRowTextValue>
                </CardRow>
                <CardRow>
                  <CardRowText>Encashment Date: </CardRowText>
                  <CardRowTextValue>TBA by {tokenConfig[item].protocol} Official</CardRowTextValue>
                </CardRow>
                <CardRow>
                  <Button variant={true ? "contained" : "outlined"} disabled sx={{ width: "100%", margin: "20px 0px" }} component={NavLink} to={`/encashment/${item}` + referralParam}>
                    Encashment your rights & benefits
                  </Button>
                </CardRow>
              </OperationCard>
            </Grid> : <Grid key={item} item xs={12} sm={6} md={4} >
                <OperationCard width="95%%" title={tokenConfig[item].protocol}>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", minWidth: "350px", minHeight: "50px", padding: "20px 0px" }} >
                    <img src={tokenConfig[item].logo} height="40" style={{ marginTop: 0 }} alt={item} />
                    <Typography color="text.primary" variant='h4' sx={{fontWeight: 800, textAlign: 'right', paddingLeft: '10px'}} >
                      {tokenConfig[item].symbol}
                    </Typography>
                  </Box>
                  <Divider sx={{width: '100%', color: 'primary.second', p: '20px 0px'}}>
                    <Chip label="Base Info" size="small" color="primary" />
                  </Divider>
                  <CardRow>
                    <CardRowText>Point-Token Name: </CardRowText>
                    <CardRowTextValue>{tokenConfig[item].name}</CardRowTextValue>
                  </CardRow>
                  <CardRow>
                    <CardRowText>Point-Token Symbol: </CardRowText>
                    <CardRowTextValue>${tokenConfig[item].symbol}</CardRowTextValue>
                  </CardRow>
                  <CardRow>
                    <CardRowText>Original Point Name: </CardRowText>
                    <CardRowTextValue>{tokenConfig[item].basePoint}</CardRowTextValue>
                  </CardRow>
                  <CardRow>
                    <CardRowText>Protocol Name: </CardRowText>
                    <CardRowTextValue>{tokenConfig[item].protocol}</CardRowTextValue>
                  </CardRow>
                  <CardRow>
                    <CardRowText>Supported Asset: </CardRowText>
                    <CardRowTextValue>ETH</CardRowTextValue>
                  </CardRow>
                  <Divider sx={{width: '100%', color: 'primary.second', p: '20px 0px'}}>
                    <Chip label="Point-Token Info" size="small" color="primary" />
                  </Divider>
                  <CardRow>
                    <CardRowText>Total Supply: </CardRowText>
                    <CardRowTextValue>
                      <CurrencyFormat value={fixedNumber(totalSupplys[item], 2)} displayType={'text'} thousandSeparator={true} suffix={` \$${tokenConfig[item].symbol}`} />
                    </CardRowTextValue>
                  </CardRow>
                  <CardRow>
                    <CardRowText>Your Balance: </CardRowText>
                    <CardRowTextValue>
                      <CurrencyFormat value={fixedNumber(balances[item], 2)} displayType={'text'} thousandSeparator={true} suffix={` \$${tokenConfig[item].symbol}`} />
                    </CardRowTextValue>
                  </CardRow>
                  <CardRow>
                    <CardRowText>Encashment Status: </CardRowText>
                    <CardRowTextValue>Not Yet</CardRowTextValue>
                  </CardRow>
                  <CardRow>
                    <CardRowText>Encashment Date: </CardRowText>
                    <CardRowTextValue>TBA by {tokenConfig[item].protocol} Official</CardRowTextValue>
                  </CardRow>
                  <Divider sx={{width: '100%'}} />
                  <CardRow>
                    <Button variant={true ? "contained" : "outlined"} disabled sx={{ width: "100%", margin: "20px 0px" }} component={NavLink} to={`/encashment/${item}` + referralParam}>
                      Encash your rights & benefits
                    </Button>
                  </CardRow>
                </OperationCard>
              </Grid>
          )))
        }
      </Grid>
    </Box>
  )
}
