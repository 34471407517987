import React from "react";
import "../../styles/loading.css";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";

export default function Loading() {

  return (
    <Box sx={{display: 'flex', height: '100vh', alignItems: 'center', flexDirection: 'column', justifyContent: 'start'}}>
      <Typography variant="h2" sx={{padding: "30px 10px", mt: '20vh', fontWeight: 900}} color="text.primary">
        Depoint Protocol
      </Typography>
      <div className="loading-container">
        <div className="loading"></div>
        <div id="loading-text">loading</div>
      </div>
      <Typography variant="h5" sx={{padding: "30px 10px", fontWeight: 900}} color="text.primary">Free your web3 points' liquidity</Typography>

    </Box>
  )
}
