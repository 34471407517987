import React, {useEffect} from "react";
import Nav from "./Nav";
import Footer from "./Footer";
import Container from "@mui/material/Container";
import {Outlet, useSearchParams} from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import {useDispatch, useSelector} from "react-redux";
import {selectInitStatus} from "../../slices/strategiesSlice";
import {setReferral} from "../../slices/globalParamsSlice";
import {owntheme} from "../../themes";
import {useColorScheme} from "@mui/material";
import {darkTheme, lightTheme} from "@rainbow-me/rainbowkit";
import Loading from "./Loading";


export default function Page() {
  const initStatus = useSelector(selectInitStatus);
  const [queryParameters] = useSearchParams();
  const dispatch = useDispatch();

  const newReferral = queryParameters.get("referral");

  const { mode } = useColorScheme();

  useEffect(() => {
    dispatch(setReferral(newReferral));
  }, [newReferral, dispatch]);

  return (
    <Container maxWidth={false} disableGutters sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      // background: owntheme.vars.palette.bg
      // backgroundImage: owntheme.vars.palette.imageBG,
      // backgroundRepeat: "no-repeat",
      // backgroundSize: "cover",
    }}>
      {
        (mode === 'light') ? <div className="lightBackground">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>

        </div> : <div className="darkBackground">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      }

      <Nav />
      <Box sx={{ width: "70%", minHeight: "700px"}}>
        {
          initStatus ? <Outlet /> : <Loading />
        }
      </Box>
      <Footer />
    </Container>
  )
}
