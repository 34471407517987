import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {poolConfig} from "../config/pools";
import axios from "axios";
import {convertBigIntToNumberWithDecimal, fixedNumber} from "../utils/common";

const initialState = {
  strategiesInfo: {
    status: false,
    error: null,
    data: {},
    list: []
  },
  globalParams: {
    ethPrice: 0,
    elTVL: 0,
    baseAPR: 0,
  },
  status: false,
  initStatus: false,
};

poolConfig.forEach((item) => {
  initialState.strategiesInfo.data[item.id] = {
    ...item
  };
  initialState.strategiesInfo.list.push(item);
})

export const strategiesSlice = createSlice({
  name: 'strategies',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchStrategiesInfo.fulfilled, (state, action) => {
        state.strategiesInfo.status = true;
        state.initStatus = true;
        action.payload[0].forEach((item) => {
          state.strategiesInfo.data[item.id].externalTVL = item.tvl;
          state.strategiesInfo.data[item.id].baseAPR = item.baseAPR;
        });
        state.globalParams.ethPrice = action.payload[1];
        state.globalParams.elTVL = action.payload[2];
        state.globalParams.baseAPR = action.payload[3];
      })
      .addCase(fetchStrategiesInfo.rejected, (state, action) => {
        state.strategiesInfo.status = false;
        state.strategiesInfo.error = action.payload;
      })
  }
})

export const fetchStrategiesInfo = createAsyncThunk('strategies/fetchStrategiesInfo', async () => {

  let strategiesInfoResult = [];

  for (const item of poolConfig) {
    let tvl = 0;
    let baseAPR = 0;
    try {
      const response = await axios.get(item.externalTVLURL);
      tvl = fixedNumber(response.data.currentChainTvls.Ethereum, 2);
    } catch (error) {
      console.log('Failed to fetch External TVL.');
    }
    try {
      const aprResponse = await axios.get(item.baseAPRURL);
      baseAPR = aprResponse.data;
    } catch (error) {
      console.log('Failed to fetch Base APR.');
    }

    strategiesInfoResult.push({
      id: item.id,
      tvl: tvl,
      baseAPR: baseAPR,
    });
  }

  const priceURL = "https://api.geckoterminal.com/api/v2/networks/eth/tokens/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2";
  let ethPrice = 3700;
  try {
    const response = await axios.get(priceURL);
    ethPrice = convertBigIntToNumberWithDecimal(response.data.data.attributes.price_usd, 0);
  } catch (error) {
    console.log('Failed to fetch ETH Price.');
  }

  const elTVLURL = "https://api.llama.fi/protocol/eigenlayer";
  let elTVL = 0;
  try {
    const responseELTVL = await axios.get(elTVLURL);
    elTVL = fixedNumber(responseELTVL.data.currentChainTvls.Ethereum, 2);
  } catch (error) {
    console.log('Failed to fetch EL TVL.');
  }

  const baseAPRURL = "https://v3.svc.swellnetwork.io/api/tokens/sweth/apr";
  let baseAPR = 0;
  try {
    const responseBaseAPR = await axios.get(baseAPRURL);
    baseAPR = fixedNumber(responseBaseAPR.data, 2);
  } catch (error) {
    console.log('Failed to fetch Base APR.');
  }

  return [strategiesInfoResult, ethPrice, elTVL, baseAPR];
});


// Action creators are generated for each case reducer function
export const selectStrategies = (state) => state.strategies.strategiesInfo.data
export const selectStrategiesList = (state) => state.strategies.strategiesInfo.list
export const selectGlobalParams = (state) => state.strategies.globalParams
export const selectInitStatus= (state) => state.strategies.initStatus
export default strategiesSlice.reducer
