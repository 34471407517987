import React from "react";
import {Typography} from "@mui/material";

export default function CardRowText({children}) {
  return (
    <Typography color="text.secondary" variant='subtitle1' sx={{textAlign: 'left', maxWidth: '200px'}}>
      {children}
    </Typography>
  )
}
