import {etherFiStrategyABI} from "../assets/abis/pools/etherFiStrategyABI";
import {kelpStrategyABI} from "../assets/abis/pools/kelpStrategyABI";
import {renzoStrategyABI} from "../assets/abis/pools/renzoStrategyABI";
import {swellStrategyABI} from "../assets/abis/pools/swellStrategyABI";

import EtherFiLogo from "../assets/imgs/protocols/EtherFi.png";
import KelpDAOLogo from "../assets/imgs/protocols/KelpDAO.png";
import RenzoLogo from "../assets/imgs/protocols/Renzo.png";
import SwellLogo from "../assets/imgs/protocols/Swell.png";

import axios from "axios";
import {IDepointDistributorABI} from "../assets/abis/iDepointDistributorABI";
import {Typography} from "@mui/material";
import React from "react";

const poolConfig = [
  {
    name: "EtherFi Pool",
    id: "0xf072e9D59648B2789b527Aab4E69D0AD9C329CED".toLowerCase(),
    logo: EtherFiLogo,
    contracts: {
      'strategy': {
        address: '0xf072e9D59648B2789b527Aab4E69D0AD9C329CED',
        abi: etherFiStrategyABI,
        chainId: 1,
      },
      'distributor': {
        address: '0x5F4d56F31bd73b0c643e14826A7F15bBbc8Bf40c',
        abi: IDepointDistributorABI,
        chainId: 1,
      },
    },
    rewardPoints: [
      "EigenLayerPointToken",
      "EtherFiPointToken",
    ],
    vestingRewardDescs: [
      {
        name: "EigenLayer",
        rewardToken: "EigenLayerPointToken",
        rewardRate: 24,
        rewardTip: "",
        desc:
          <Typography
            color="text.primary"
            variant='subtitle1'
            sx={{fontWeight: 800, textAlign: 'right', textOverflow: 'ellipsis', overflow: 'hidden'}}
            component='a'
            href={`https://docs.eigenlayer.xyz/eigenlayer/restaking-guides/restaking-user-guide/restaked-points#overview`}
          >
            Check details.
          </Typography>,
        descURL: "",
      },
      {
        name: "EtherFi",
        rewardToken: "EtherFiPointToken",
        rewardRate: 1000,
        rewardTip: "",
        desc: <Typography
          color="text.primary"
          variant='subtitle1'
          sx={{fontWeight: 800, textAlign: 'right', textOverflow: 'ellipsis', overflow: 'hidden'}}
          component='a'
          href={`https://etherfi.gitbook.io/etherfi/getting-started/loyalty-points`}
        >
          Check details.
        </Typography>,
        descURL: "",
      },
    ],
    referralPoint: "EtherFiPointToken",
    referralRewardDesc: {
      name: "EtherFi Point",
      rewardToken: "EtherFiPointToken",
      rewardAmount: 10000,
      rewardTip: "",
      desc: <Typography
        color="text.primary"
        variant='subtitle1'
        sx={{fontWeight: 800, textOverflow: 'ellipsis', overflow: 'hidden'}}
      >
        Each 0.1 ETH staked earns the person who stakes and the the person who referred 100 loyalty points. <Typography
          color="text.primary"
          variant='subtitle1'
          sx={{fontWeight: 800, textAlign: 'right', textOverflow: 'ellipsis', overflow: 'hidden'}}
          component='a'
          href={`https://etherfi.gitbook.io/etherfi/getting-started/loyalty-points/referrals`}
        >Check details.</Typography>
      </Typography>,
    },
    getRealRewards: async (address) => {
      let rewardURL = "/etherfi/api/portfolio/v2/" + address;
      let result = [
        {
          rewardToken: "EigenLayerPointToken",
          symbol: "De-ELP",
          amount: 0,
        },
        {
          rewardToken: "EtherFiPointToken",
          symbol: "De-EFP",
          amount: 0,
        }
      ]

      if (address) {
        try {
          const response = await axios.get(rewardURL);

          result[0].amount = Number(response.data.eigenlayerPoints);
          result[1].amount = Number(response.data.loyaltyPoints);
        } catch (error) {
          result[0].amount = 0;
          result[1].amount = 0;
          console.log("Failed to fetch real rewards.")
        }
      }
      return result;
    },
    network: "Ethereum",
    chainId: 1,
    desc: "The Recursive Staking strategy amplifies liquid staking rewards by 3X-7X in a delta-neutral and liquidation protected manner.",
    protocol: "EtherFi",
    externalTVLURL: "https://api.llama.fi/protocol/ether.fi",
    supportAsset: [
      'ETH',
    ],
    shareName: "mETH",
    baseAPRURL: "https://v3.svc.swellnetwork.io/api/tokens/sweth/apr",
  },
  {
    name: "KelpDAO Pool",
    id: "0x90BAdCDD0de8F8D610b282637f834B52d5b6B7F7".toLowerCase(),
    logo: KelpDAOLogo,
    contracts: {
      'strategy': {
        address: '0x90BAdCDD0de8F8D610b282637f834B52d5b6B7F7',
        abi: kelpStrategyABI,
        chainId: 1,
      },
      'distributor': {
        address: '0x7b79c7Dcd50bD6C14350111c7138C256EDb2Cb4c',
        abi: IDepointDistributorABI,
        chainId: 1,
      },
    },
    rewardPoints: [
      "EigenLayerPointToken",
      "KelpPointToken",
    ],
    vestingRewardDescs: [
      {
        name: "EigenLayer Point",
        rewardToken: "EigenLayerPointToken",
        rewardRate: 24,
        rewardTip: "",
        desc: <Typography
            color="text.primary"
            variant='subtitle1'
            sx={{fontWeight: 800, textAlign: 'right', textOverflow: 'ellipsis', overflow: 'hidden'}}
            component='a'
            href={`https://docs.eigenlayer.xyz/eigenlayer/restaking-guides/restaking-user-guide/restaked-points#overview`}
          >
            Check here
          </Typography>,
        descURL: "",
      },
      {
        name: "KelpDAO Point",
        rewardToken: "KelpPointToken",
        rewardRate: 10000,
        rewardTip: "",
        desc: <Typography
            color="text.primary"
            variant='subtitle1'
            sx={{fontWeight: 800, textAlign: 'right', textOverflow: 'ellipsis', overflow: 'hidden'}}
            component='a'
            href={`https://kelp.gitbook.io/kelp/explore-rseth/kelp-miles-and-eigenlayer-points`}
          >
            Check here
          </Typography>,
        descURL: "",
      },
    ],
    referralPoint: "KelpPointToken",
    referralRewardDesc: {
      name: "KelpDAO Point",
      rewardToken: "KelpPointToken",
      rewardAmount: 10000,
      rewardTip: "",
      desc: <Typography
        color="text.primary"
        variant='subtitle1'
        sx={{fontWeight: 800, textOverflow: 'ellipsis', overflow: 'hidden'}}
      >
        Your friends earn 10% extra Kelp Miles through your link. You earn upto 20% of Kelp Miles accrued by your referrals. <Typography
          color="text.primary"
          variant='subtitle1'
          sx={{fontWeight: 800, textAlign: 'right', textOverflow: 'ellipsis', overflow: 'hidden'}}
          component='a'
          href={`https://kelp.gitbook.io/kelp/explore-rseth/referrals`}
        >Check details.</Typography>
      </Typography>,
    },
    getRealRewards: async (address) => {
      let rewardURL = "https://common.kelpdao.xyz/km-el-points/user/" + address;
      let result = [
        {
          rewardToken: "EigenLayerPointToken",
          symbol: "De-ELP",
          amount: 0,
        },
        {
          rewardToken: "KelpPointToken",
          symbol: "De-KDP",
          amount: 0,
        }
      ]

      if (address) {
        try {
          const response = await axios.get(rewardURL);
          result[0].amount = Number(response.data.value.elPoints);
          result[1].amount = Number(response.data.value.kelpMiles);
        } catch (error) {
          result[0].amount = 0;
          result[1].amount = 0;
          console.log("Failed to fetch real rewards.")
        }
      }
      return result;
    },
    network: "Ethereum",
    chainId: 1,
    desc: "The Recursive Staking strategy amplifies liquid staking rewards by 3X-7X in a delta-neutral and liquidation protected manner.",
    protocol: "KelpDAO",
    externalTVLURL: "https://api.llama.fi/protocol/kelp-dao",
    supportAsset: [
      'ETH'
    ],
    shareName: "mETH",
    baseAPRURL: "https://v3.svc.swellnetwork.io/api/tokens/sweth/apr",
  },
  {
    name: "Renzo Pool",
    id: "0x97410511CA25124fa874A3c9E48d4b97a0Db0249".toLowerCase(),
    logo: RenzoLogo,
    contracts: {
      'strategy': {
        address: '0x97410511CA25124fa874A3c9E48d4b97a0Db0249',
        abi: renzoStrategyABI,
        chainId: 1,
      },
      'distributor': {
        address: '0x53EF0Cdc9f5570Aca42c331c05B2811FF707D3C8',
        abi: IDepointDistributorABI,
        chainId: 1,
      },
    },
    rewardPoints: [
      "EigenLayerPointToken",
      "RenzoPointToken",
    ],
    vestingRewardDescs: [
      {
        name: "EigenLayer Point",
        rewardToken: "EigenLayerPointToken",
        rewardRate: 24,
        rewardTip: "",
        desc: <Typography
            color="text.primary"
            variant='subtitle1'
            sx={{fontWeight: 800, textAlign: 'right', textOverflow: 'ellipsis', overflow: 'hidden'}}
            component='a'
            href={`https://docs.eigenlayer.xyz/eigenlayer/restaking-guides/restaking-user-guide/restaked-points#overview`}
          >
            Check here
          </Typography>,
        descURL: "",
      },
      {
        name: "Renzo Point",
        rewardToken: "RenzoPointToken",
        rewardRate: "24 ~ 72",
        rewardTip: "",
        desc: <Typography
            color="text.primary"
            variant='subtitle1'
            sx={{fontWeight: 800, textAlign: 'right', textOverflow: 'ellipsis', overflow: 'hidden'}}
            component='a'
            href={`https://docs.renzoprotocol.com/docs/product/renzo-ezpoints`}
          >
            Check here
          </Typography>,
        descURL: "",
      },
    ],
    referralPoint: "RenzoPointToken",
    referralRewardDesc: {
      name: "Renzo Point",
      rewardToken: "RenzoPointToken",
      rewardAmount: "Additional 10% of the referred users points.",
      rewardTip: "",
      desc:
        <Typography
          color="text.primary"
          variant='subtitle1'
          sx={{fontWeight: 800, textOverflow: 'ellipsis', overflow: 'hidden'}}
        >
          For each person who joins Renzo using your referral link, will earn an additional 10% of the referred users points.  Example:  If you refer to a user who earns 1,000 ezPoints you will earn 10% or 100 ezPoints for referring that user. <Typography
          color="text.primary"
          variant='subtitle1'
          sx={{fontWeight: 800, textAlign: 'right', textOverflow: 'ellipsis', overflow: 'hidden'}}
          component='a'
          href={`https://docs.renzoprotocol.com/docs/product/renzo-ezpoints`}
        >Check details.</Typography>
        </Typography>
    },
    getRealRewards: async (address) => {
      let rewardURL = `/renzo/api/points/${address}?chainId=1`;
      let result = [
        {
          rewardToken: "EigenLayerPointToken",
          symbol: "De-ELP",
          amount: 0,
        },
        {
          rewardToken: "RenzoPointToken",
          symbol: "De-RZP",
          amount: 0,
        }
      ]

      if (address) {
        try {
          const response = await axios.get(rewardURL);

          result[0].amount = Number(response.data.data.totals.eigenLayerPoints);
          result[1].amount = Number(response.data.data.totals.renzoPoints);
        } catch (error) {
          result[0].amount = 0;
          result[1].amount = 0;
          console.log("Failed to fetch real rewards.")
        }
      }
      return result;
    },
    network: "Ethereum",
    chainId: 1,
    desc: "The Recursive Staking strategy amplifies liquid staking rewards by 3X-7X in a delta-neutral and liquidation protected manner.",
    protocol: "Renzo",
    externalTVLURL: "https://api.llama.fi/protocol/renzo",
    supportAsset: [
      'ETH'
    ],
    shareName: "mETH",
    baseAPRURL: "https://v3.svc.swellnetwork.io/api/tokens/sweth/apr",
  },
  {
    name: "Swell Pool",
    id: "0x2C52ddbD10806A4460Ae805696DF24D40644BB5B".toLowerCase(),
    logo: SwellLogo,
    contracts: {
      'strategy': {
        address: '0x2C52ddbD10806A4460Ae805696DF24D40644BB5B',
        abi: swellStrategyABI,
        chainId: 1,
      },
      'distributor': {
        address: '0x31867f4d8e07B959310af4a07f35830fd84ef0A1',
        abi: IDepointDistributorABI,
        chainId: 1,
      },
    },
    rewardPoints: [
      "EigenLayerPointToken",
      "SwellPointToken",
    ],
    vestingRewardDescs: [
      {
        name: "EigenLayer Point",
        rewardToken: "EigenLayerPointToken",
        rewardRate: 24,
        rewardTip: "",
        desc: <Typography
            color="text.primary"
            variant='subtitle1'
            sx={{fontWeight: 800, textAlign: 'right', textOverflow: 'ellipsis', overflow: 'hidden'}}
            component='a'
            href={`https://docs.eigenlayer.xyz/eigenlayer/restaking-guides/restaking-user-guide/restaked-points#overview`}
          >
            Check here
          </Typography>,
        descURL: "",
      },
      {
        name: "Swell Point",
        rewardToken: "SwellPointToken",
        rewardRate: 24,
        rewardTip: "Since Swell's holding pearl rewards rate is not public, we set it to the default 24 Token/Days per ETH. During the waiting for encashment stage, the Pearls actually obtained will be distributed to Token holders in proportion.",
        desc: <Typography
            color="text.primary"
            variant='subtitle1'
            sx={{fontWeight: 800, textAlign: 'right', textOverflow: 'ellipsis', overflow: 'hidden'}}
            component='a'
            href={`https://www.swellnetwork.io/post/the-voyage`}
          >
            Check here
          </Typography>,
        descURL: "",
      },
    ],
    referralPoint: "SwellPointToken",
    referralRewardDesc: null,
    getRealRewards: async (address) => {
      let rewardEigenLayerPointURL = `https://v3-lrt.svc.swellnetwork.io/swell.v3.EigenPointsService/EigenPointsUser?connect=v1&encoding=json&message=%7B%22address%22%3A%22${address}%22%7D`;
      let rewardPearlURL = `https://v3.svc.swellnetwork.io/swell.v3.VoyageService/VoyageUser?connect=v1&encoding=json&message=%7B%22address%22%3A%22${address}%22%7D`;
      let result = [
        {
          rewardToken: "EigenLayerPointToken",
          symbol: "De-ELP",
          amount: 0,
        },
        {
          rewardToken: "SwellPointToken",
          symbol: "De-ELP",
          amount: 0,
        }
      ]

      if (address) {
        try {
          const responseEigenLayer = await axios.get(rewardEigenLayerPointURL);
          const responseSwell = await axios.get(rewardPearlURL);
          result[0].amount = responseEigenLayer.data.points ? Number(responseEigenLayer.data.points) : 0;
          result[1].amount = responseSwell.data.points ? Number(responseSwell.data.points) : 0;
        } catch (error) {
          result[0].amount = 0;
          result[1].amount = 0;
          console.log("Failed to fetch real rewards.")
        }
      }
      return result;
    },
    network: "Ethereum",
    chainId: 1,
    desc: "The Recursive Staking strategy amplifies liquid staking rewards by 3X-7X in a delta-neutral and liquidation protected manner.",
    protocol: "Swell",
    externalTVLURL: "https://api.llama.fi/protocol/swell-liquid-restaking",
    supportAsset: [
      'ETH'
    ],
    shareName: "mETH",
    baseAPRURL: "https://v3.svc.swellnetwork.io/api/tokens/sweth/apr",
  },
]


export {poolConfig};
