import { createSlice } from '@reduxjs/toolkit'


export const themeSlice = createSlice({
  name: 'myTheme',
  initialState: {
    value: "light",
  },
  reducers: {
    setThemeMode: (state, action) => {
      state.value = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setThemeMode } = themeSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectTheme = (state) => state.myTheme.value

export default themeSlice.reducer
