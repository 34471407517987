import React from "react";
import {Typography} from "@mui/material";

export default function SecondTitle({children}) {
  return (
    <Typography variant="h4" color='text.primary' sx={{m: '30px 0px'}}>
      {children}
    </Typography>
  )
}
