import useContractCall from "./utils/useContractCall";
import * as ethers from "@ethersproject/abi";
import {assetConfig} from "../../config/tokens";
import {walletFactory} from "../../config/contracts";
import {convertToEtherAccuracy} from "../../utils/common";


export default function useCreateWalletAndDeposit(asset, depositAmount, strategy, referral) {
  depositAmount = convertToEtherAccuracy(depositAmount);
  let functionName;
  let callParams;
  let msgValue = 0;
  if (asset === "ETH") {
    functionName = "depositETH";
    callParams = [referral];
    msgValue = depositAmount;
  } else {
    functionName = "depositAsset";
    callParams = [assetConfig[asset].address, depositAmount];
  }

  const abiCoder = new ethers.AbiCoder();
  const iface = new ethers.Interface(strategy.contracts.strategy.abi);
  const data = iface.encodeFunctionData(functionName, callParams);
  const callData = abiCoder.encode(['address', 'bytes'], [strategy.contracts.strategy.address, data]);

  const desc = "Create wallet."
  return useContractCall(walletFactory, "createWallet", [strategy.contracts.strategy.address, callData], msgValue, desc);
  // return useContractCall(targetWalletContract, "execute", callParams, msgValue, desc);
}
