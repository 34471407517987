import React, {useEffect, useState} from "react";
import {
  Box,
  Typography,
  Button,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormControl,
  ButtonGroup, Card, Divider,
} from "@mui/material";
import {Link, NavLink, useParams} from "react-router-dom";
import OperationCard from "../components/custom/OperationCard";
import CardRow from "../components/custom/CardRow";
import CardRowText from "../components/custom/CardRowText";
import SecondTitle from "../components/custom/SecondTitle";
import FirstTitle from "../components/custom/FirstTitle";
import ThumbnailCard from "../components/custom/ThumbnailCard";
import {useSelector} from "react-redux";
import {selectGlobalParams, selectStrategies} from "../../slices/strategiesSlice";
import GoBackButton from "../components/custom/GoBackButton";
import {useAccount, useBalance, useContractReads} from "wagmi";
import {assetConfig, assetList, tokenConfig} from "../../config/tokens";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import useDeposit from "../../hooks/writes/useDeposit";
import {BASE_URL, ZERO_ADDRESS} from "../../config/constants";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {erc20ABI} from "../../assets/abis/erc20ABI";
import InfoCard from "../components/custom/InfoCard";
import {useLocation} from "react-router";
import {CopyToClipboard} from "react-copy-to-clipboard";
import CustomAlert from "../components/CustomAlert";
import {walletFactory} from "../../config/contracts";
import useCreateWalletAndDeposit from "../../hooks/writes/useCreateWalletAndDeposit";
import useClaim from "../../hooks/writes/useClaim";
import {selectReferral} from "../../slices/globalParamsSlice";
import {convertBigIntToNumberWithDecimal, fixedNumber} from "../../utils/common";
import {calculateDailyEstimateRewards} from "../../utils/paramsCalculator";
import CardRowTextValue from "../components/custom/CardRowTextValue";
import * as CurrencyFormat from "react-currency-format";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import CardRowValue from "../components/custom/CardRowValue";

export default function Pool() {
  const newReferral = useSelector(selectReferral);
  const globalParams = useSelector(selectGlobalParams);
  const referralParam = newReferral ? "?referral=" + newReferral : "";

  const location = useLocation();
  let { id } = useParams();
  const { address } = useAccount();
  const strategies = useSelector(selectStrategies);

  const strategy = strategies[id];
  const referral = newReferral ? newReferral : ZERO_ADDRESS;
  const referralLink = BASE_URL + location.pathname + '?referral=' + address;

  const [copyStatus, setCopyStatus] = useState(false);
  const [walletAddr, setWalletAddr] = useState(ZERO_ADDRESS);
  const [internalTVL, setInternalTVL] = useState(0);
  const [internalTVLValue, setInternalTVLValue] = useState(0);
  const [depositValue, setDepositValue] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);
  const [userBalance, setUserBalance] = useState({});
  const [userRewards, setUserRewards] = useState({});
  const [userEstimateRewards, setUserEstimateRewards] = useState({});
  const [referralReward, setReferralReward] = useState(0);
  const [userRealRewards, setUserRealRewards] = useState(strategy.rewardPoints.map((item) => {
    return {
      rewardToken: item,
      symbol: tokenConfig[item].symbol,
      amount: 0,
    }
  }));
  const [userClaimedRewards, setUserClaimedRewards] = useState([0,0]);

  const [selectedAsset, setSelectedAsset] = useState(strategy.supportAsset[0]);
  const [newDepositAmount, setNewDepositAmount] = useState(0);
  const [newUnstakeAmount, setNewUnstakeAmount] = useState(0);
  const [newWithdrawAmount, setNewWithdrawAmount] = useState(0);
  const [selectedManageAction, setSelectedManageAction] = useState("deposit");

  useBalance({
    address: address,
    watch: true,
    allowFailure: true,
    onSuccess(data) {

      userBalance['ETH'] = Number(data.formatted);
      setUserBalance(userBalance);
    },
  })

  useContractReads({
    contracts: assetList.map((item) => {
      let tokenContract;
      if (item !== "ETH") {
        tokenContract = {
          address: assetConfig[item].address,
          abi: erc20ABI,
          functionName: "balanceOf",
          args: [
            address
          ]
        }
      }
      return tokenContract;
    }),
    watch: true,
    allowFailure: true,
    onSuccess(data) {
      // console.log(data);
      strategy.supportAsset.forEach((item, index) => {
        if (item !== "ETH") {
          userBalance[item] = Number(data[index].result);
        }
      })
      setUserBalance(userBalance);
    },
  });

  useContractReads({
    contracts: [
      {
        ...walletFactory,
        functionName: 'userWallets',
        args: [address],
      },
      {
        ...strategy.contracts.strategy,
        functionName: 'totalStakedAmount',
      },
      {
        ...strategy.contracts.strategy,
        functionName: 'getAvailableRewards',
        args: [address]
      },
      {
        ...strategy.contracts.strategy,
        functionName: 'stakedShares',
        args: [address]
      },
      {
        ...strategy.contracts.strategy,
        functionName: 'getUserStakedValue',
        args: [address]
      },
      {
        ...strategy.contracts.distributor,
        functionName: 'getClaimedRewards',
        args: [address]
      },
    ],
    watch: true,
    allowFailure: true,
    onSuccess(data) {
      if (data[0].status === "success") {
        setWalletAddr(data[0].result);
      }

      if (data[1].status === "success") {
        setInternalTVL(convertBigIntToNumberWithDecimal(data[1].result, 18));
        setInternalTVLValue(convertBigIntToNumberWithDecimal(data[1].result, 18) * globalParams.ethPrice);
      }

      if (data[2].status === "success") {
        strategy.rewardPoints.forEach((item,index) => {
          userRewards[item] = convertBigIntToNumberWithDecimal(data[2].result[index], 18);
          userEstimateRewards[item] = calculateDailyEstimateRewards(depositAmount, strategy.vestingRewardDescs[index].rewardRate);
        });

        if (data[2].result.length > 2) {
          setReferralReward(convertBigIntToNumberWithDecimal(data[2].result[2], 18));
        }
        console.log(data[2]);
        setUserRewards(userRewards);
      }

      if (data[3].status === "success") {
        setDepositAmount(convertBigIntToNumberWithDecimal(data[3].result, 18));
      }

      if (data[4].status === "success") {
        setDepositValue(convertBigIntToNumberWithDecimal(data[4].result, 18));
      }

      if (data[5].status === "success") {
        let newResult = [
          convertBigIntToNumberWithDecimal(data[5].result[0], 18),
          convertBigIntToNumberWithDecimal(data[5].result[1], 18),
        ];
        setUserClaimedRewards(newResult);
      }
    },
  });

  useEffect(() => {
    strategy.getRealRewards(address).then((data) => {
        setUserRealRewards(data);
      }
    );
  }, [address, strategy])

  const createWalletAndDeposit = useCreateWalletAndDeposit(selectedAsset, newDepositAmount, strategy, referral);
  const deposit = useDeposit(walletAddr, selectedAsset, newDepositAmount, strategy, referral);
  const claim = useClaim(strategy);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <GoBackButton to={"/pools/" + referralParam} />
        <FirstTitle>
          {strategy.protocol} Tokenization Pool Detail
        </FirstTitle>
      </Box>

      <SecondTitle>
        Basic Info
      </SecondTitle>

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <ThumbnailCard title="Target Protocol TVL" value={
          <Typography color="text.primary" variant='h5' >
            <CurrencyFormat value={strategy.externalTVL} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          </Typography>
        } minWidth='20%' />
        <ThumbnailCard title="Current Pool Deposit" value={
          <Typography color="text.primary" variant='h5' >
            {internalTVL} ETH (
            <CurrencyFormat value={fixedNumber(internalTVLValue, 2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            )
          </Typography>
        } minWidth='20%' />
        <ThumbnailCard title="Base APY" value={
          <Typography color="text.primary" variant='h5' >
            {fixedNumber(strategy.baseAPR, 2)}%
          </Typography>
        } minWidth='20%' tips={
          <Typography color="white" variant='subtitle1' sx={{fontWeight: 800, p: '5px'}} >
            Native ETH staking APY.
          </Typography>
        } />
        <Card sx={{
          minWidth: '20%',
          minHeight: 170,
          // border: 2,
          borderRadius: '20px',
          // borderColor: 'primary.main',
          // boxShadow: '0 5px 10px 2px rgba(255, 105, 135, .3)',
        }}>
          <Box sx={{textAlign: "left", padding: "20px", display: "flex", alignItems: "cCardRowTextValueenter"}}>
            <Typography color="primary.main" variant='h6' gutterBottom>
              Points to be Tokenized
            </Typography>
          </Box>
          <Box sx={{justifyContent: "flex-end", padding: "20px", display: 'flex', alignItems: "center"}}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "right", height: "30"}}>
              {(strategy.rewardPoints.map((item) => (
                <Box key={item} sx={{padding: "0px 2px" }} >
                  <img src={tokenConfig[item].logo} height="30" style={{ marginTop: 0 }} alt={strategy.name} />
                </Box>
              )))}
            </Box>
            <Tooltip title={
              <Typography color="white" variant='subtitle1' sx={{fontWeight: 800, p: '5px'}} >
                By participating in this pool, you can obtain two types of Point-Token, including {strategy.rewardPoints[0]} and {strategy.rewardPoints[1]}.
              </Typography>
            }>
              <IconButton>
                <InfoIcon  sx={{color: "text.primary"}}/>
              </IconButton>
            </Tooltip>
          </Box>
        </Card>
      </Box>
      <SecondTitle>
        Pool Details
      </SecondTitle>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "start"}}>
        <Box sx={{ width: "40%" }}>
          <OperationCard title="Tokenization Explanation">
            <CardRow>
              <Typography color="text.secondary" variant='subtitle1' sx={{textAlign: 'left'}}>
                Depoint will strictly follow the corresponding point distribution mechanism and implement it on-chain through smart contracts to ensure that the Point-Tokens on-chain are basically consistent with the number of centralized points actually obtained.
                <br />
                <br />
                What needs to be clear is that the asset deposit by users will be staked to the target protocol through the each exclusive agent wallet. Users can enjoy restaking benefits  while the risks are isolated at the same time.              </Typography>
            </CardRow>
            <Divider sx={{width: '100%', color: 'primary.second', p: '10px 0px'}} />
            <CardRow>
              <CardRowText>Pool Contract Address: </CardRowText>
              <Box sx={{justifyContent: "flex-end", paddingLeft: "20px", paddingTop: "10px", paddingBottom: '10px', maxWidth: '50%', display: 'flex', alignItems: "center"}}>
                <Typography
                  color="text.primary"
                  variant='subtitle1'
                  sx={{fontWeight: 800, textAlign: 'right', textOverflow: 'ellipsis', overflow: 'hidden'}}
                  component='a'
                  href={`https://etherscan.io/address/${strategy.contracts.strategy.address}`}
                >
                  {/*{strategy.contracts.strategy.address}*/}
                  {ZERO_ADDRESS}
                </Typography>
                <Tooltip title={
                  <Typography color="white" variant='subtitle1' sx={{fontWeight: 800, p: '5px'}} >
                    This contract implements the core business logic of the tokenization pool, including deposit, claim Point-Tokens, etc.
                  </Typography>
                }>
                  <IconButton>
                    <InfoIcon fontSize='small' sx={{color: "text.secondary"}}/>
                  </IconButton>
                </Tooltip>
              </Box>
            </CardRow>
            <CardRow>
              <CardRowText>Point Distributor Address: </CardRowText>
              <Box sx={{justifyContent: "flex-end", paddingLeft: "20px", paddingTop: "10px", paddingBottom: '10px', display: 'flex', alignItems: "center", maxWidth: '50%'}}>
                <Typography
                  color="text.primary"
                  variant='subtitle1'
                  sx={{fontWeight: 800, textAlign: 'right', textOverflow: 'ellipsis', overflow: 'hidden'}}
                  component='a'
                  href={`https://etherscan.io/address/${strategy.contracts.distributor.address}`}
                >
                  {/*{strategy.contracts.distributor.address}*/}
                  {ZERO_ADDRESS}
                </Typography>
                <Tooltip title={
                  <Typography color="white" variant='subtitle1' sx={{fontWeight: 800, p: '5px'}} >
                    This contract is used to implement the distribution logic of Point-Token. This logic is implemented strictly in accordance with the settings of the target project to ensure the consistency of the number of Point-Token on-chain and Point off-chain.
                  </Typography>
                }>
                  <IconButton>
                    <InfoIcon fontSize='small' sx={{color: "text.secondary"}}/>
                  </IconButton>
                </Tooltip>
              </Box>
            </CardRow>
            <CardRow>
              <CardRowText>Agent Wallet Address: </CardRowText>
              <Box sx={{justifyContent: "flex-end", paddingLeft: "20px", paddingTop: "10px", paddingBottom: '10px', display: 'flex', alignItems: "center", maxWidth: '50%'}}>
                <Typography
                  color="text.primary"
                  variant='subtitle1'
                  sx={{fontWeight: 800, textAlign: 'right', textOverflow: 'ellipsis', overflow: 'hidden'}}
                  component='a'
                  href={`https://etherscan.io/address/${walletAddr}`}
                >
                  {walletAddr}
                </Typography>
                <Tooltip title={
                  <Typography color="white" variant='subtitle1' sx={{fontWeight: 800, p: '5px'}} >
                    This contract is the user's exclusive on-chain wallet. Depoint responds to the user's request through the on-chain wallet and manages the assets deposited by the user into the target project.                  </Typography>
                }>
                  <IconButton>
                    <InfoIcon fontSize='small' sx={{color: "text.secondary"}}/>
                  </IconButton>
                </Tooltip>
              </Box>
            </CardRow>
            <CardRow>
              <Typography color="primary.main" variant='subtitle1' sx={{textAlign: 'left', maxWidth: '200px'}}>
                Accumulated Original Points:
              </Typography>
              <Box sx={{textAlign: "right"}}>
                {
                  (userRealRewards.map((item) => (
                    <CardRowTextValue key={item.rewardToken} tips={
                      <Typography color="white" variant='subtitle1' sx={{fontWeight: 800, p: '5px'}} >
                        Accumulated original {tokenConfig[item.rewardToken].basePoint} at {tokenConfig[item.rewardToken].protocol}.
                      </Typography>
                    }>
                      <CurrencyFormat value={item.amount} displayType={'text'} thousandSeparator={true} suffix={` ${tokenConfig[item.rewardToken].basePoint}`} />
                    </CardRowTextValue>
                  )))
                }
              </Box>
            </CardRow>
            <CardRow>
              <CardRowText>Tokenization Mapping: </CardRowText>
              <Box sx={{textAlign: "right"}}>
                {
                  (strategy.rewardPoints.map((item) => (
                    <CardRowTextValue key={item} tips={
                      <Typography color="white" variant='subtitle1' sx={{fontWeight: 800, p: '5px'}} >
                        In Depoint, the name of the tokenized ERC20 Token for {tokenConfig[item].basePoint} is {tokenConfig[item].name} and the symbol is {tokenConfig[item].symbol}
                      </Typography>
                    }>
                      {tokenConfig[item].basePoint} -> ${tokenConfig[item].symbol}
                    </CardRowTextValue>
                  )))
                }
              </Box>
            </CardRow>
          </OperationCard>
          <OperationCard title="Point-Tokenization Mechanism">
            <CardRow>
              <Typography color="text.secondary" variant='subtitle1' sx={{textAlign: 'left'}}>
                The {strategy.protocol} Tokenization Pool contains two distribution mechanisms for tokenized Points, including vesting distribution mechanism and referral reward mechanism.
              </Typography>
            </CardRow>
            <Divider sx={{width: '100%', color: 'primary.second', p: '20px 0px'}} >
              <Chip label="1. Vesting Distribution Rule" size="small" color="primary" />
            </Divider>
            <CardRow>
              <Typography color="text.secondary" variant='subtitle1' sx={{textAlign: 'left'}}>
                This mechanism distributes points at a fixed rate. For example, assuming the rate is 1 Point / Sec per deposited ETH, when user A deposits 5 ETH, after 24 hours, A will receive 5 * 1 * 24 * 3600 Points.
              </Typography>
            </CardRow>
            <CardRow>
              <Typography color="text.secondary" variant='subtitle1' sx={{textAlign: 'left'}}>
                When you deposit ETH, you will continue to obtain the following Point-Tokens at the same time. Note that these tokens need to be actively claimed by yourself.
              </Typography>
            </CardRow>
            {
              (strategy.vestingRewardDescs.map((item) => (
                <InfoCard width="90%" backgroundColor="#FFDFDF" title={item.name} key={item.name}>
                  <CardRow>
                    <CardRowText>Point-Token Name: </CardRowText>
                    <CardRowTextValue>{tokenConfig[item.rewardToken].name}</CardRowTextValue>
                  </CardRow>
                  <CardRow>
                    <CardRowText>Point-Token Symbol: </CardRowText>
                    <CardRowTextValue>${tokenConfig[item.rewardToken].symbol}</CardRowTextValue>
                  </CardRow>
                  <CardRow>
                    <CardRowText>Distribution Rate: </CardRowText>
                    <CardRowTextValue>{`${item.rewardRate} ${tokenConfig[item.rewardToken].symbol}/Day per ETH`}</CardRowTextValue>
                  </CardRow>
                  <CardRow>
                    <CardRowText>Target Protocol Rule: </CardRowText>
                    <CardRowValue>{item.desc}</CardRowValue>
                  </CardRow>
                </InfoCard>
              )))
            }
            {
              (strategy.referralRewardDesc) ? <>
                <Divider sx={{width: '100%', color: 'primary.second', p: '20px 0px'}}>
                  <Chip label="2. Referral Rewards Rule" size="small" color="primary" />
                </Divider>
                <CardRow>
                  <Typography color="text.secondary" variant='subtitle1' sx={{textAlign: 'left'}}>
                    This mechanism is used to simulate the referral mechanism of the corresponding project on the chain. If a user uses your referral link to participate in the project, you will get the corresponding additional rewards.
                  </Typography>
                </CardRow>
                <InfoCard width="90%" backgroundColor="#FFDFDF" title={strategy.referralRewardDesc.name}>
                  <CardRow>
                    <CardRowText>Point-Token Name: </CardRowText>
                    <CardRowTextValue>{tokenConfig[strategy.referralRewardDesc.rewardToken].name}</CardRowTextValue>
                  </CardRow>
                  <CardRow>
                    <CardRowText>Point-Token Symbol: </CardRowText>
                    <CardRowTextValue>${tokenConfig[strategy.referralRewardDesc.rewardToken].symbol}</CardRowTextValue>
                  </CardRow>
                  <CardRow>
                    <CardRowText>Reward Rule Desc: </CardRowText>
                    <CardRowValue>{strategy.referralRewardDesc.desc}</CardRowValue>
                  </CardRow>
                </InfoCard>
              </>: <></>
            }

          </OperationCard>

        </Box>
        <Box sx={{ width: "55%" }}>
          <OperationCard title="Tokenize">
            <Divider sx={{width: '100%', color: 'primary.second', p: '10px 0px'}} />
            <CardRow>
              <CardRowText>Your Deposit Asset: </CardRowText>
              <CardRowTextValue>{depositValue} ETH </CardRowTextValue>
            </CardRow>
            <CardRow>
              <CardRowText>Your Wallet Balance: </CardRowText>
              <Box>
                {
                  (strategy.supportAsset.map((item) => (
                    <CardRowTextValue key={item}>
                      <CurrencyFormat value={userBalance[item] ? fixedNumber(userBalance[item], 2) : 0} displayType={'text'} thousandSeparator={true} suffix={` ${item}`} />
                    </CardRowTextValue>
                  )))
                }
              </Box>
            </CardRow>
            <CardRow>
              <CardRowText>Your Claimable Vesting Distributed Point-Token: </CardRowText>
              <Box sx={{textAlign: "right"}}>
                {
                  (strategy.rewardPoints.map((item) => (
                    <CardRowTextValue key={item}>
                      <CurrencyFormat value={userRewards[item] ? fixedNumber(userRewards[item], 2) : 0} displayType={'text'} thousandSeparator={true} suffix={` \$${tokenConfig[item].symbol}`} />
                    </CardRowTextValue>
                  )))
                }
              </Box>
            </CardRow>
            <CardRow>
              <CardRowText>Your 24H Estimate Vesting Distributed Point-Token: </CardRowText>
              <Box sx={{textAlign: "right"}}>
                {
                  (strategy.rewardPoints.map((item) => (
                    <CardRowTextValue key={item}>
                      <CurrencyFormat value={userEstimateRewards[item] ? fixedNumber(userEstimateRewards[item], 2) : 0} displayType={'text'} thousandSeparator={true} suffix={` \$${tokenConfig[item].symbol}`} />
                    </CardRowTextValue>
                  )))
                }
              </Box>
            </CardRow>
            <CardRow>
              <CardRowText>Your Claimable Referral Point-Token: </CardRowText>
              <CardRowTextValue>
                <CurrencyFormat value={referralReward} displayType={'text'} thousandSeparator={true} suffix={` \$${tokenConfig[strategy.referralPoint].symbol}`} />
              </CardRowTextValue>
            </CardRow>
            <CardRow>
              <CardRowText>Your Point-Token Claimed: </CardRowText>
              <Box sx={{textAlign: "right"}}>
                {
                  (userClaimedRewards.map((item,index) => (
                    <CardRowTextValue key={index}>
                      <CurrencyFormat value={item} displayType={'text'} thousandSeparator={true} suffix={` \$${tokenConfig[strategy.rewardPoints[index]].symbol}`} />
                    </CardRowTextValue>
                  )))
                }
              </Box>
            </CardRow>
            <Box sx={{width: '100%', marginTop: '20px'}}>
              <Divider sx={{width: '100%', borderColor: 'primary.second'}} />
              <ButtonGroup size="large" variant="text" sx={{width: "100%"}}>
                <Button sx={{width: "100%"}} onClick={() => {setSelectedManageAction("deposit")}}>Deposit</Button>
                <Button sx={{width: "100%"}} onClick={() => {setSelectedManageAction("unstake")}}>Unstake</Button>
                <Button sx={{width: "100%"}} onClick={() => {setSelectedManageAction("withdraw")}}>Withdraw</Button>
                <Button sx={{width: "100%"}} onClick={() => {setSelectedManageAction("claim")}}>Claim</Button>
              </ButtonGroup>
              <Divider sx={{width: '100%', borderColor: 'primary.second'}} />
            </ Box>
            {/*<Divider sx={{ marginTop: "20px", width: '100%' }}/>*/}
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "50px", padding: "0px 15px", margin: "20px 0px", width: '95%'}}>
              {
                (selectedManageAction === "deposit") ?
                  <Box sx={{ width: '100%'}}>
                    <CardRow>
                      <CardRowText>Select the asset: </CardRowText>
                      <Box sx={{ m: "20px 0px", width: "70%" }}>
                        <FormControl fullWidth>
                          <InputLabel>Asset</InputLabel>
                          <Select
                            value={selectedAsset}
                            label="Asset"
                            onChange={(event) => {
                              setSelectedAsset(event.target.value);
                            }}
                          >
                            {
                              (strategy.supportAsset.map((item) => (
                                <MenuItem
                                  key={item}
                                  sx={{ width: "100%" }}
                                  value={item}
                                >
                                  <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img src={assetConfig[item].logo} height="24" style={{ padding: "0px 10px", marginTop: 0 }} alt={item} />
                                    {item}
                                  </Box>
                                </MenuItem>
                              )))
                            }
                          </Select>
                        </FormControl>
                      </Box>
                    </CardRow>
                    <CardRow>
                      <CardRowText>Enter amount: </CardRowText>

                      <FormControl fullWidth sx={{ m: "20px 0px", width: "70%" }}>
                        <InputLabel htmlFor="outlined-adornment-amount">
                          {`Balance: ${userBalance[selectedAsset] ? fixedNumber(userBalance[selectedAsset], 2) : 0}`}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          endAdornment={<InputAdornment position="end">
                            <img src={assetConfig[selectedAsset].logo} height="24" style={{ padding: "0px 10px", marginTop: 0 }} alt={selectedAsset} />
                            <Button onClick={() => {
                              setNewDepositAmount(userBalance[selectedAsset] ? fixedNumber(userBalance[selectedAsset], 2) : 0);
                              setNewUnstakeAmount(0);
                              setNewWithdrawAmount(0);
                            }}>
                              Max
                            </Button>
                          </InputAdornment>}
                          label={`Balance: ${userBalance[selectedAsset] ? fixedNumber(userBalance[selectedAsset], 2) : 0}`}
                          value={newDepositAmount}
                          onChange={(event) => {
                            setNewDepositAmount(Number(event.target.value ? event.target.value : 0));
                            setNewUnstakeAmount(0);
                            setNewWithdrawAmount(0);
                          }}
                        />
                      </FormControl>
                    </CardRow>
                    <CardRow>
                      <Button
                        variant={ false ? "contained" : "outlined"}
                        disabled
                        sx={{ width: "100%" }}
                        onClick={() => (walletAddr !== ZERO_ADDRESS) ? deposit() : createWalletAndDeposit()}
                      >Deposit</Button>
                    </CardRow>
                  </Box> : (selectedManageAction === "unstake") ?
                  <Box sx={{ width: '100%'}}>
                    <CardRow>
                      <Typography color="text.secondary" variant='subtitle1' sx={{textAlign: 'left'}}>
                        Unstake will be effective once {strategy.protocol} Official announces.
                      </Typography>
                    </CardRow>
                    <CardRow>
                      <CardRowText>Select the asset: </CardRowText>
                      <Box sx={{ m: "20px 0px", width: "70%" }}>
                        <FormControl fullWidth>
                          <InputLabel>Asset</InputLabel>
                          <Select
                            value={selectedAsset}
                            label="Asset"
                            onChange={(event) => {
                              setSelectedAsset(event.target.value);
                            }}
                          >
                            {
                              (strategy.supportAsset.map((item) => (
                                <MenuItem
                                  sx={{ width: "100%" }}
                                  value={item}
                                >
                                  <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img src={assetConfig[item].logo} height="24" style={{ padding: "0px 10px", marginTop: 0 }} alt={item} />
                                    {item}
                                  </Box>
                                </MenuItem>
                              )))
                            }
                          </Select>
                        </FormControl>
                      </Box>
                    </CardRow>
                    <CardRow>
                      <CardRowText>Enter amount: </CardRowText>
                      <FormControl fullWidth sx={{ m: "20px 0px", width: "70%" }}>
                        <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          endAdornment={<InputAdornment position="end">{selectedAsset}</InputAdornment>}
                          label="Amount"
                          value={newUnstakeAmount}
                          onChange={(event) => {
                            setNewDepositAmount(0);
                            setNewUnstakeAmount(Number(event.target.value ? event.target.value : 0));
                            setNewWithdrawAmount(0);
                          }}
                        />
                      </FormControl>
                    </CardRow>
                    <CardRow>
                      <Button
                        variant={ false ? "contained" : "outlined"}
                        sx={{ width: "100%" }}
                        onClick={() => {}}
                        disabled
                      >Unstake</Button>
                    </CardRow>
                  </Box> : (selectedManageAction === "withdraw") ?
                  <Box sx={{ width: '100%'}}>
                    <CardRow>
                      <Typography color="text.secondary" variant='subtitle1' sx={{textAlign: 'left'}}>
                        Withdraw will be effective once {strategy.protocol} Official announces.
                      </Typography>
                    </CardRow>
                    <CardRow>
                      <CardRowText>Select the asset: </CardRowText>
                      <Box sx={{ m: "20px 0px", width: "70%" }}>
                        <FormControl fullWidth>
                          <InputLabel>Asset</InputLabel>
                          <Select
                            value={selectedAsset}
                            label="Asset"
                            onChange={(event) => {
                              setSelectedAsset(event.target.value);
                            }}
                          >
                            {
                              (strategy.supportAsset.map((item) => (
                                <MenuItem
                                  sx={{ width: "100%" }}
                                  value={item}
                                >
                                  <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img src={assetConfig[item].logo} height="24" style={{ padding: "0px 10px", marginTop: 0 }} alt={item} />
                                    {item}
                                  </Box>
                                </MenuItem>
                              )))
                            }
                          </Select>
                        </FormControl>
                      </Box>
                    </CardRow>
                    <CardRow>
                      <CardRowText>Enter amount: </CardRowText>
                      <FormControl fullWidth sx={{ m: "20px 0px", width: "70%" }}>
                        <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          endAdornment={<InputAdornment position="end">{selectedAsset}</InputAdornment>}
                          label="Amount"
                          value={newWithdrawAmount}
                          onChange={(event) => {
                            setNewDepositAmount(0);
                            setNewUnstakeAmount(0);
                            setNewWithdrawAmount(Number(event.target.value ? event.target.value : 0));
                          }}
                        />
                      </FormControl>
                    </CardRow>
                    <CardRow>
                      <Button
                        variant={ false ? "contained" : "outlined"}
                        sx={{ width: "100%" }}
                        onClick={() => {}}
                        disabled
                      >Withdraw</Button>
                    </CardRow>
                  </Box> :
                  <Box sx={{ width: '100%'}}>
                    <CardRow>
                      <Typography color="text.primary" variant='subtitle1' sx={{m: '30px 0px'}} >
                        You will get total Point-Token include {userRewards[strategy.rewardPoints[0]] + referralReward} ${tokenConfig[strategy.rewardPoints[0]].symbol} and {userRewards[strategy.rewardPoints[1]]} ${tokenConfig[strategy.rewardPoints[1]].symbol}.
                      </Typography>
                    </CardRow>
                    <CardRow>
                      <Button
                        variant={ false ? "contained" : "outlined"}
                        disabled
                        sx={{ width: "100%" }}
                        onClick={claim}
                      >Claim All Point-Tokens</Button>
                    </CardRow>
                  </Box>
              }
            </Box>
          </OperationCard>
          {
            (address && strategy.referralRewardDesc) ?
              <OperationCard title="Generate Your Referral Link">
              <CardRow>
                <Typography color="text.secondary" variant='subtitle1' sx={{textAlign: 'left'}}>
                  {strategy.referralRewardDesc.desc}
                </Typography>
              </CardRow>
              <CardRow>
                <CopyToClipboard text={referralLink} onCopy={() => setCopyStatus(true)}>
                  <Button
                    variant={ false ? "contained" : "outlined"}
                    sx={{ width: "100%" }}
                    onClick={() => {}}
                  >Copy Your Referral Link</Button>
                </CopyToClipboard>
              </CardRow>
              <CustomAlert desc={"Copy success!"} status={copyStatus} setStatus={setCopyStatus} />
            </OperationCard> :
              <Box />
          }
        </Box>
      </Box>
    </Box>
  )
}


