import { createSlice } from '@reduxjs/toolkit'

export const globalParamsSlice = createSlice({
  name: 'globalParams',
  initialState: {
    referral: "",
  },
  reducers: {
    setReferral: (state, action) => {
      state.referral = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setReferral } = globalParamsSlice.actions
export const selectReferral = (state) => state.globalParams.referral;

export default globalParamsSlice.reducer
