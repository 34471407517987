import * as ethers from "@ethersproject/units";

export function convertToEtherAccuracy(value) {
  return ethers.parseEther(value.toString());
}

export function convertBigIntToNumberWithDecimal(value, decimal) {
  return Number((Number(value.toString()) / (10 ** decimal)).toFixed(4));
}

export function fixedNumber(value, fractionDigits) {
  return Number((Number(value.toString())).toFixed(fractionDigits));
}
