import React from "react";
import {Box, Card} from "@mui/material";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

export default function ThumbnailCard({title, value, minWidth='30%', tips}) {
  return (
    <Card sx={{
      minWidth,
      minHeight: 170,
      // border: 2,
      borderRadius: '20px',
      // borderColor: 'primary.main',
      // boxShadow: '0 5px 10px 2px rgba(255, 105, 135, .3)',
      bgcolor: 'background.default',
      opacity: 0.85,
    }}>
      <Box sx={{textAlign: "left", padding: "20px", display: "flex", alignItems: "center"}}>
        <Typography color="primary.main" variant='h6' gutterBottom>
          {title}
        </Typography>
      </Box>
      <Box sx={{justifyContent: "flex-end", padding: "20px", display: 'flex', alignItems: "center"}}>
        {value}
        {
          tips ? <Tooltip title={tips}>
            <IconButton>
              <InfoIcon sx={{color: "text.primary"}}/>
            </IconButton>
          </Tooltip> : <div />
        }
      </Box>
    </Card>
  )
}
