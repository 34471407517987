import React from "react";
import {Box} from "@mui/material";
import PointToken from "../../assets/imgs/white-dp-token-logo-150.png"
import "../../styles/giftBox.css";

export default function GiftBox() {
  return (
    <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '800px'}}>
      <div className="gift-box">
        <div className="gift-box-body">
          <img className="img" src={PointToken} height='130px'/>
          <div className="gift-box-lid">
            <div className="gift-box-bowtie"/>
          </div>
        </div>
      </div>
    </Box>
  )
}
