import React from "react";
import {Button} from "@mui/material";
import {NavLink} from "react-router-dom";

export default function GoBackButton({to}) {
  return (
    <NavLink end to={to}>
      <Button variant="outlined" size='large' >
        ️Go Back
      </Button>
    </NavLink>
  )
}
