import {useContractWrite, useWaitForTransaction} from "wagmi";
import {setTxPayload} from "../../../slices/txPayloadSlice";
import {useAddRecentTransaction} from "@rainbow-me/rainbowkit";
import {useDispatch} from "react-redux";

export default function useContractCall(contract, functionName, args, msgValue, desc) {
  const dispatch = useDispatch();
  const addRecentTransaction = useAddRecentTransaction();

  // const { config } = usePrepareContractWrite({
  //   ...contract,
  //   functionName,
  //   args,
  //   enabled: false
  // })

  const config = {
    ...contract,
    functionName,
    args,
    value: msgValue,
  }
  const x = useContractWrite({
    ...config,
    onError(error) {
      console.log('error', error.message)
    },
    onSettled(data, error) {
      console.log('Settled', { data, error })
    },
    onSuccess(data) {
      console.log('Success', data)
      addRecentTransaction({
        hash: data.hash,
        description: desc
      });
    },
  })

  useWaitForTransaction({
    hash: x.data?.hash,
    onSuccess(data) {
      dispatch(setTxPayload({ txHash: x.data.hash, status: "success" }));
      console.log("this is success", x.data.hash, x.isLoading, x.isError, x.isSuccess);
    },
    onError(data) {
      dispatch(setTxPayload({ txHash: x.data.hash, status: "error" }));
      console.log("this is error", x.data.hash, x.isLoading, x.isError, x.isSuccess);
    },
  })

  return x.write;
  //
  // console.log(waitForTransaction.status, waitForTransaction.isLoading, waitForTransaction.isSuccess,
  //   waitForTransaction.isError,
  //   waitForTransaction.isFetched,
  //   waitForTransaction.fetchStatus,
  // );
}
