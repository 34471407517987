import React from "react";
import {Box, Card, useColorScheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import {owntheme} from "../../../themes";

export default function OperationCard({title, children}) {

  return (
    <Card sx={{
      display: "flex",
      justifyItems: "center",
      flexDirection: 'column',
      textAlign: "center",
      padding: "20px",
      margin: "20px 0px",
      // border: 2,
      borderRadius: '20px',
      // borderColor: 'primary.main',
      boxShadow: 3,
      // background: 'none'
      // background: owntheme.vars.palette.bg,
      bgcolor: 'background.default',
      opacity: 0.85,
    }}>
      <Box sx={{ display: "flex", justifyContent: "left", alignItems: "center", width: "95%", padding: "10px 15px 2px"}}>
        <Typography color="primary.main" variant='h5' gutterBottom>
          {title}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: 'column'}}>
        {children}
      </Box>
    </Card>
  )
}
