import { configureStore } from '@reduxjs/toolkit'
import themeReducer from './slices/themeSlice'
import strategiesReducer from './slices/strategiesSlice'
import txPayloadReducer from './slices/txPayloadSlice'
import globalParamsReducer from './slices/globalParamsSlice'

export default configureStore({
  reducer: {
    myTheme: themeReducer,
    strategies: strategiesReducer,
    txPayload: txPayloadReducer,
    globalParams: globalParamsReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
});
