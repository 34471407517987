import React from "react";
import {Card} from "@mui/material";
import Typography from "@mui/material/Typography";

export default function InfoCard({title, backgroundColor, width, children, minHeight="200px"}) {
  return (
    <Card variant="outlined" sx={{ minHeight: minHeight, width: width, textAlign: "center", padding: "20px", margin: "20px 0px", backgroundColor: 'background.infoCard' }}>
      <Typography color="primary.main" variant='h6' gutterBottom>
        {title}
      </Typography>
      {children}
    </Card>
  )
}
