import React, {useState} from "react";
import Box from "@mui/material/Box";
import GoBackButton from "../components/custom/GoBackButton";
import FirstTitle from "../components/custom/FirstTitle";
import SecondTitle from "../components/custom/SecondTitle";
import {useSelector} from "react-redux";
import {selectGlobalParams, selectStrategies} from "../../slices/strategiesSlice";
import {selectReferral} from "../../slices/globalParamsSlice";
import {useParams} from "react-router-dom";
import {tokenConfig} from "../../config/tokens";
import ThumbnailCard from "../components/custom/ThumbnailCard";
import {convertBigIntToNumberWithDecimal} from "../../utils/common";
import OperationCard from "../components/custom/OperationCard";
import CardRow from "../components/custom/CardRow";
import CardRowText from "../components/custom/CardRowText";
import Button from "@mui/material/Button";
import InfoCard from "../components/custom/InfoCard";
import {useAccount, useContractReads} from "wagmi";
import {Typography} from "@mui/material";
import CardRowTextValue from "../components/custom/CardRowTextValue";
import * as CurrencyFormat from "react-currency-format";

export default function Convert() {
  let { id } = useParams();
  const { address } = useAccount();
  const globalParams = useSelector(selectGlobalParams);
  const strategiesInfo = useSelector(selectStrategies);

  const referral = useSelector(selectReferral);
  const referralParam = referral ? "?referral=" + referral : "";
  const targetProtocol = tokenConfig[id];

  const [totalSupply, setTotalSupply] = useState(0);
  const [balance, setBalance] = useState(0);

  const tvl = id === "EigenLayerPointToken" ? globalParams.elTVL : strategiesInfo[targetProtocol.strategyID].externalTVL;

  useContractReads({
    contracts: [
      {
        ...tokenConfig[id].contract,
        functionName: 'totalSupply',
      },
      {
        ...tokenConfig[id].contract,
        functionName: 'balanceOf',
        args: [address]
      }
    ],
    watch: true,
allowFailure: true,
    onSuccess(data) {
      if (data[0].status === 'success') {
        setTotalSupply(convertBigIntToNumberWithDecimal(data[0].result, 18));
      }

      if (data[1].status === 'success') {
        setBalance(convertBigIntToNumberWithDecimal(data[1].result, 18));
      }
    },
  });

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <GoBackButton to={"/converts/" + referralParam} />
        <FirstTitle>
          {targetProtocol.protocol} Point-Token Encashment
        </FirstTitle>
      </Box>

      <SecondTitle>
        Base Info
      </SecondTitle>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <ThumbnailCard title="Target Protocol TVL" value={
          <Typography color="text.primary" variant='h5' >
            <CurrencyFormat value={tvl} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          </Typography>
        } minWidth={375} />
        <ThumbnailCard title="Point-Token Total Supply" value={
          <Typography color="text.primary" variant='h5' >
            <CurrencyFormat value={totalSupply} displayType={'text'} thousandSeparator={true} suffix={` $${tokenConfig[id].symbol}`} />
          </Typography>
        } minWidth={375} />
        <ThumbnailCard title="Base APY" value={
          <Typography color="text.primary" variant='h5' >
            {globalParams.baseAPR}%
          </Typography>
        } minWidth={375} tips="ETH staking basic APY" />
      </Box>
      <SecondTitle>
        Encashment Info
      </SecondTitle>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "start"}}>
        <Box sx={{ textAlign: "center", width: "45%"}} >
          <OperationCard title="Point Rights & Benefits Info">
            <CardRow>
              <CardRowText>Name: </CardRowText>
              <CardRowTextValue>{targetProtocol.protocol}</CardRowTextValue>
            </CardRow>
            <CardRow>
              <CardRowText>Website: </CardRowText>
              <Typography
                color="text.primary"
                variant='subtitle1'
                sx={{fontWeight: 800, textAlign: 'right', maxWidth: '50%', textOverflow: 'ellipsis', overflow: 'hidden'}}
                component='a'
                href={targetProtocol.website}
              >
                {targetProtocol.website}
              </Typography>
            </CardRow>
            <CardRow>
              <CardRowText>X Links: </CardRowText>
              <Typography
                color="text.primary"
                variant='subtitle1'
                sx={{fontWeight: 800, textAlign: 'right', maxWidth: '50%', textOverflow: 'ellipsis', overflow: 'hidden'}}
                component='a'
                href={targetProtocol.twitter}
              >
                {targetProtocol.twitter}
              </Typography>
            </CardRow>
            <CardRow>
              <CardRowText>Community: </CardRowText>
              <Typography
                color="text.primary"
                variant='subtitle1'
                sx={{fontWeight: 800, textAlign: 'right', maxWidth: '50%', textOverflow: 'ellipsis', overflow: 'hidden'}}
                component='a'
                href={targetProtocol.community}
              >
                {targetProtocol.community}
              </Typography>
            </CardRow>
            {/*<InfoCard width="90%" backgroundColor="#FFDFDF" title="Point Distribute Rules">*/}
            {/*  {targetProtocol.rules}*/}
            {/*</InfoCard>*/}
            <InfoCard width="90%" backgroundColor="#FFDFDF" title="Point Rights & Benefits">
              {targetProtocol.rights}
            </InfoCard>
          </OperationCard>
        </Box>
        <Box sx={{ textAlign: "center", width: "45%"}} >
          <OperationCard title="Your Point-Token Info">
          <CardRow>
            <CardRowText>Your Balance: </CardRowText>
            <CardRowTextValue>
              <CurrencyFormat value={balance} displayType={'text'} thousandSeparator={true} suffix={` $${tokenConfig[id].symbol}`} />
            </CardRowTextValue>
          </CardRow>
          <CardRow>
            <CardRowText>Encashment Rules: </CardRowText>
            <CardRowTextValue>TBA by {targetProtocol.protocol} Official</CardRowTextValue>

          </CardRow>
          <CardRow>
            <Button variant={ false ? "contained" : "outlined"} sx={{width: "100%"}} disabled>Encashment</Button>
          </CardRow>
        </OperationCard>
        </Box>
      </Box>
    </Box>
  )
}
