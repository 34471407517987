import React from "react";
import {Box, Typography} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";

export default function CardRowValue({children, tips}) {
  return (
    <Box sx={{justifyContent: "flex-end", paddingLeft: "20px", paddingTop: "10px", paddingBottom: '10px', display: 'flex', alignItems: "center", maxWidth: '260px'}}>
      <Box sx={{textAlign: 'right'}} >
        {children}
      </Box>
      {
        (tips) ? <Tooltip title={tips}>
            <IconButton>
              <InfoIcon fontSize='small' sx={{color: "text.secondary"}}/>
            </IconButton>
          </Tooltip> :
          <div />
      }

    </Box>
  )
}
